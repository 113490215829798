import { DocumentNode, gql } from "@apollo/client";
import { useMemo } from "react";

import type {
  ArticleExposureIcon_articleExposureFragment,
  ArticleExposureIcon_exposureFragment,
  ExposureTypeIcon_exposureFragment,
} from "@/gql-types";

import {
  type ArticleExposureState,
  ArticleExposureIcon as BaseArticleExposureIcon,
  ExposureTypeIcon as BaseExposureTypeIcon,
} from "../components/ExposureIcon";

type ExposureTypeIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  exposure: ExposureTypeIcon_exposureFragment;
};

export const ExposureTypeIcon: React.FC<ExposureTypeIconProps> & {
  fragments: { exposure: DocumentNode };
} = ({ exposure, ...props }) => (
  <BaseExposureTypeIcon
    label={exposure.label}
    acronym={exposure.acronym}
    icon={exposure.icon}
    {...props}
  />
);

ExposureTypeIcon.fragments = {
  exposure: gql`
    fragment ExposureTypeIcon_exposure on Exposure {
      id
      label
      icon
      acronym
    }
  `,
};

type ArticleExposureIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  exposure: ArticleExposureIcon_exposureFragment;
  articleExposure: ArticleExposureIcon_articleExposureFragment;
};

export const ArticleExposureIcon: React.FC<ArticleExposureIconProps> & {
  fragments: {
    exposure: DocumentNode;
    articleExposure: DocumentNode;
  };
} = ({ exposure, articleExposure, ...props }) => {
  const state = useMemo((): ArticleExposureState => {
    if (articleExposure.online) return "online";
    if (articleExposure.fulfilled) return "fulfilled";
    if (articleExposure.selected) return "selected";
    return "suggested";
  }, [articleExposure]);
  return (
    <BaseArticleExposureIcon
      label={exposure.label}
      acronym={exposure.acronym}
      icon={exposure.icon}
      state={state}
      {...props}
    />
  );
};
ArticleExposureIcon.fragments = {
  exposure: gql`
    fragment ArticleExposureIcon_exposure on Exposure {
      id
      label
      icon
      acronym
    }
  `,
  articleExposure: gql`
    fragment ArticleExposureIcon_articleExposure on ArticleExposure {
      suggested
      fulfilled
      selected
      online
    }
  `,
};
