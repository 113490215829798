import { Portal } from "@ariakit/react";
import {
  CharacterMetadata,
  ContentBlock,
  EditorState,
  SelectionState,
  getVisibleSelectionRect,
} from "draft-js-es";
import { useEffect, useMemo, useRef, useState } from "react";
import { useEventCallback } from "swash/utils/useEventCallback";

export interface State {
  lockFocus: () => void;
  unlockFocus: () => void;
  editorState: Draft.EditorState;
  setEditorState: (
    editorState:
      | Draft.EditorState
      | ((editorState: Draft.EditorState) => Draft.EditorState),
  ) => void;
  handleKeyCommand: (
    command: string,
    editorState: Draft.EditorState,
    eventTimeStamp: number,
  ) => Draft.DraftHandleValue;
  keyBindingFn: (e: React.KeyboardEvent<object>) => string | null;
  anchorBlock: Draft.ContentBlock | null;
  hasFocus: boolean;
  plugins: any[];
  activePlugins: any[];
  readOnly: boolean;
  editorId: string;
  articleId: number;
  name: string;
  label: string;
  expanded: boolean;
  multiline: boolean;
  whiteSpace: string;
  focusLocked: boolean;
  allowedAttributes: Record<string, string[]>;
  audioEnabled: boolean;
  blockTemplates: BlockTemplate[];
}

interface BlockTemplate {
  id: number;
  name: string;
  title: string;
  type: string;
  editorType: string;
  css: Record<string, any>;
  enabled: string;
  __typename: string;
}

interface UsePluginPopover {
  state: State;
  onEscape: () => void;
  initialEdit: boolean;
  keepOpenedOnClick: boolean;
  // Keep the popover opened on click outside
  keepOpen?: boolean;
  maxWidth?: number;
}

export const usePluginPopover = ({
  state,
  onEscape,
  initialEdit,
  keepOpenedOnClick,
  keepOpen,
  maxWidth = 292,
}: UsePluginPopover) => {
  const { hasFocus, unlockFocus } = state;
  const inputRef = useRef<HTMLInputElement>();
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const popoverRef = useRef<HTMLDivElement>();
  const [edit, setEdit] = useState(initialEdit);
  const [position, setPosition] = useState<{ top: number; left: number }>();
  const selection = state.editorState.getSelection();

  const style = useMemo(() => {
    return {
      maxWidth,
      left: position?.left ?? -9999,
      top: position?.top ?? -9999,
    };
  }, [maxWidth, position?.left, position?.top]);

  const handleClick = useEventCallback((event: MouseEvent) => {
    timeout.current = setTimeout(() => {
      if (keepOpen) return;
      if (event.defaultPrevented) return;
      if (!popoverRef.current) return;
      if (
        popoverRef.current === event.target ||
        popoverRef.current.contains(event.target as Node)
      ) {
        if (keepOpenedOnClick) setEdit(true);
        return;
      }
      setEdit(false);
      onEscape();
    });
  });

  // Handle click outside
  useEffect(() => {
    document.body.addEventListener("mousedown", handleClick);
    return () => document.body.removeEventListener("mousedown", handleClick);
  }, [handleClick]);

  useEffect(() => {
    // When unmounted, we want to remove it if empty
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
      onEscape();
      unlockFocus();
    };
  }, [onEscape, unlockFocus]);

  useEffect(() => {
    if (!hasFocus || !selection) return undefined;
    const id = requestAnimationFrame(() => {
      const selectionRect = getVisibleSelectionRect(window);
      if (selectionRect) {
        setPosition({
          top: selectionRect.bottom + 10,
          left: selectionRect.left - 40,
        });
      }
    });
    return () => {
      cancelAnimationFrame(id);
    };
  }, [selection, hasFocus]);

  useEffect(() => {
    if (!edit && !position) return;
    const raf = requestAnimationFrame(() => {
      inputRef.current?.focus();
    });

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [edit, position, inputRef, initialEdit]);

  return {
    edit,
    popoverRef,
    setEdit,
    position,
    inputRef,
    style,
  };
};

interface PluginPopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  pluginPopoverState: ReturnType<typeof usePluginPopover>;
}

export const PluginPopover = ({
  pluginPopoverState,
  children,
}: PluginPopoverProps) => {
  const { popoverRef, style } = pluginPopoverState;
  return (
    <Portal>
      <div
        className="absolute z-editor-popover w-full"
        style={style}
        ref={popoverRef as React.RefObject<HTMLDivElement>}
      >
        {children}
      </div>
    </Portal>
  );
};

export function getAnchorEntity(state: State) {
  const entityKey = getAnchorEntityKey(state);
  if (!entityKey) return null;
  const { editorState } = state;
  const entity = editorState.getCurrentContent().getEntity(entityKey);
  return entity ?? null;
}

export function forceSelection(
  state: State,
  { editorState = state.editorState } = {},
) {
  state.setEditorState(
    EditorState.forceSelection(editorState, editorState.getSelection()),
  );
}

export function getAnchorEntityKey({ anchorBlock, editorState }: State) {
  if (!anchorBlock) return null;
  const selection = editorState.getSelection();

  for (let i = selection.getStartOffset(); i <= selection.getEndOffset(); i++) {
    const entityKey = anchorBlock.getEntityAt(i);
    if (entityKey) return entityKey;
  }

  return null;
}

export function getEntityRanges(
  contentBlock: ContentBlock,
  entityKey: string | null,
) {
  const selections: SelectionState[] = [];
  contentBlock.findEntityRanges(
    (character: CharacterMetadata) => {
      const characterEntityKey = character.getEntity();
      return entityKey === characterEntityKey;
    },
    (start: number, end: number) => {
      const selection = SelectionState.createEmpty(contentBlock.getKey()).merge(
        {
          anchorOffset: start,
          focusOffset: end,
        },
      );
      selections.push(selection);
    },
  );
  return selections;
}
