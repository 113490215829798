import { gql } from "@apollo/client";

import { ArticleLayoutPricingIndicator } from "@/containers/article/ArticleLayoutPricingIndicator";
import type { CapsuleLayoutEditorialTypeNbChar_articleFragment } from "@/gql-types";

type CapsuleLayoutEditorialTypeNbCharProps = {
  article: CapsuleLayoutEditorialTypeNbChar_articleFragment;
};

export const CapsuleLayoutEditorialTypeNbChar = ({
  article,
}: CapsuleLayoutEditorialTypeNbCharProps) => {
  return (
    <ArticleLayoutPricingIndicator
      article={article}
      tooltip={
        <>
          #{article.id}
          <span className="mx-2 opacity-50">|</span>
          {article.editorialFormat && <>{article.editorialFormat.label} - </>}
          {article.layout.label} - {article.editorialType.label}
          <span className="mx-2 opacity-50">|</span>
          {article.nbChar} signes
        </>
      }
    />
  );
};

CapsuleLayoutEditorialTypeNbChar.fragments = {
  article: gql`
    fragment CapsuleLayoutEditorialTypeNbChar_article on Article {
      id
      nbChar
      editorialFormat {
        id
        label
      }
      editorialType {
        id
        label
      }
      ...ArticleLayoutPricingIndicator_article
    }
    ${ArticleLayoutPricingIndicator.fragments.article}
  `,
};
