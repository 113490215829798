import { memo } from "react";
import { cn } from "swash/utils/classNames";

import { NamedIcon } from "./NamedIcon";

export type ArticleExposureState =
  | "fulfilled"
  | "selected"
  | "suggested"
  | "online";

type ExposureTypeIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  label: string;
  icon?: string | null;
  acronym?: string | null;
};

export const ExposureTypeIcon = memo<ExposureTypeIconProps>(
  ({ label, icon, acronym, ...props }) => {
    if (!props.height && !props.width) props.width = 20;

    // FIXME: It seems that this icon is missing
    if (icon === "CopyPrint") icon = "PaperCopy";

    return (
      <svg
        viewBox="0 0 20 20"
        aria-label={label}
        {...props}
        className={cn("-m-px", props.className)}
      >
        {icon ? (
          <NamedIcon name={icon} x={1} y={1} size={18} />
        ) : (
          <LetterIcon y={16}>{acronym}</LetterIcon>
        )}
      </svg>
    );
  },
);

type ArticleExposureIconProps = ExposureTypeIconProps & {
  state: ArticleExposureState;
};

export const ArticleExposureIcon = memo<ArticleExposureIconProps>(
  ({ label, icon, acronym, state, ...props }) => {
    if (!props.height && !props.width) props.width = 20;

    return (
      <svg
        viewBox="0 0 20 20"
        aria-label={label}
        {...props}
        className={cn(
          "-m-px",
          !["fulfilled", "online"].includes(state)
            ? "text-grey-on"
            : "text-dusk-on",
          props.className,
        )}
      >
        {icon ? (
          <NamedIcon x={(20 - 13) / 2} y={1} size={13} name={icon} />
        ) : (
          <LetterIcon y={14}>{acronym}</LetterIcon>
        )}
        <StateLine state={state} />
      </svg>
    );
  },
);

type LetterIconProps = React.ComponentPropsWithoutRef<"text">;

const LetterIcon: React.FC<LetterIconProps> = ({ children, ...props }) => {
  if (typeof children !== "string") return null;
  const isSingleChar = children.length === 1;
  return (
    <text
      x={10}
      width={18}
      textAnchor="middle"
      textLength={isSingleChar ? undefined : 18}
      fontSize="19"
      fontWeight="600"
      fill="currentColor"
      lengthAdjust="spacingAndGlyphs"
      style={{ userSelect: "none", fontFamily: "var(--font-sans)" }}
      {...props}
    >
      {children}
    </text>
  );
};

type StateLineProps = (React.ComponentPropsWithoutRef<"svg"> &
  React.ComponentPropsWithoutRef<"path">) & {
  state: ArticleExposureState;
};

const StateLine: React.FC<StateLineProps> = ({ state, ...props }) => {
  switch (state) {
    case "fulfilled":
      // Checked line
      return (
        <g {...props}>
          <path d="M1 17h10l2 2H1V17Z" fill="currentColor" />
          <path
            fill="none"
            stroke="#32AD81"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="m19 16-3 3-2-2"
          />
        </g>
      );
    case "selected":
      // Full line
      return <path d="M1 17h18v2H1z" fill="currentColor" {...props} />;
    case "suggested":
      // Dashed line
      return (
        <path
          d="M1 17h3v2H1zm5 0h3v2H6zm5 0h3v2h-3zm5 0h3v2h-3z"
          fill="currentColor"
          {...props}
        />
      );

    case "online":
      return (
        <g {...props}>
          <path
            d="M2 17H14C13.4477 17.5523 13.4477 18.4477 14 19H2V17Z"
            fill="currentColor"
          />
          <circle
            cx="17"
            cy="18"
            r="2"
            className="animate-online text-green-bg-strong"
            fill="currentColor"
          />
        </g>
      );
  }
};
