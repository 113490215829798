import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client";
import { toIdValue } from "apollo-utilities";

import introspectionQueryResultData from "@/config/fragmentTypes";

const cacheRedirects = {
  Query: {
    stagingArea: (_, args) =>
      toIdValue(
        cache.config.dataIdFromObject({
          __typename: "StagingArea",
          id: args.id,
        }),
      ),
    article: (_, args) =>
      toIdValue(
        cache.config.dataIdFromObject({ __typename: "Article", id: args.id }),
      ),
  },
};

const keepIncoming = (existing, incoming) => incoming;

export const cache = new InMemoryCache({
  addTypename: true,
  cacheRedirects,
  possibleTypes: introspectionQueryResultData.possibleTypes,
  dataIdFromObject: (object) => {
    if (object.gid) return object.gid;
    switch (object.__typename) {
      case "ArticleStagingAreaContent":
      case "TagStagingAreaContent":
        return `${object.stagingAreaId}:${object.contentType}:${object.contentId}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        exposure: {
          merge: keepIncoming,
        },
      },
    },
    Config: {
      merge: true,
    },
    DbConfig: {
      keyFields: [],
      merge: true,
    },
    DbConfigArticles: {
      keyFields: [],
      merge: true,
    },
    LegacyCustomFields: {
      merge: true,
    },
    Article: {
      fields: {
        sections: {
          merge: keepIncoming,
        },
        articleExposures: {
          merge: keepIncoming,
          keyArgs: ["gid"],
        },
        authors: {
          merge: keepIncoming,
        },
        signatures: {
          merge: keepIncoming,
        },
        medias: {
          merge: keepIncoming,
        },
        nodes: {
          merge: keepIncoming,
        },
        planning: {
          merge: true,
        },
        customFields: {
          merge: keepIncoming,
        },
        suggestedMedias: {
          merge: keepIncoming,
        },
        publishInfos: {
          merge: true,
        },
        comments: {
          merge: true,
        },
        notes: {
          merge: true,
        },
        events: {
          keyArgs: ["where", "orderBy"],
        },
        allTeasers: {
          merge: keepIncoming,
        },
        tags: {
          merge: keepIncoming,
        },
        forecastInsights: {
          merge: true,
        },
      },
    },
    ForecastInsights: {
      fields: {
        conversions: {
          merge: keepIncoming,
        },
        pageViews: {
          merge: keepIncoming,
        },
        webTrafficSources: {
          merge: keepIncoming,
        },
        engagement: {
          merge: keepIncoming,
        },
      },
    },
    Author: {
      fields: {
        usedIn_articles: { merge: keepIncoming },
        usedIn_posts: { merge: keepIncoming },
      },
    },
    Image: {
      fields: {
        usedIn_articles: { merge: keepIncoming },
        usedIn_posts: { merge: keepIncoming },
      },
    },
    Post: {
      fields: {
        nodes: {
          merge: keepIncoming,
        },
      },
    },
    CampaignStagingArea: {
      fields: {
        contents: {
          merge: keepIncoming,
        },
      },
    },
    PeriodicalStagingArea: {
      fields: {
        contents: {
          merge: keepIncoming,
        },
      },
    },
    PublicationStagingArea: {
      fields: {
        contents: {
          merge: keepIncoming,
        },
      },
    },
    TagStagingArea: {
      fields: {
        contents: {
          merge: keepIncoming,
        },
      },
    },
    Exposure: {
      fields: {
        articleExposures: {
          merge: keepIncoming,
        },
      },
    },
    ContributionLiveInput: {
      fields: {
        tags: {
          merge: keepIncoming,
        },
      },
    },
    ArticleMedia: {
      fields: {
        metadata: {
          merge: true,
        },
      },
    },
    User: {
      fields: {
        settings: {
          merge: true,
        },
      },
    },
    CommentThread: {
      fields: {
        responses: { merge: keepIncoming },
      },
    },
    EditorNodeText: {
      fields: {
        comments: { merge: keepIncoming },
      },
    },
    Predicate: {
      fields: {
        pricingTiers: { merge: keepIncoming },
      },
    },
    Fridge: {
      fields: {
        folders: {
          merge: keepIncoming,
        },
      },
    },
    FridgeFolder: {
      fields: {
        contents: {
          merge: keepIncoming,
        },
      },
    },
    ArticleTeaserContextField: {
      keyFields: ["name"],
    },
    Tag: {
      fields: {
        customFields: {
          merge: keepIncoming,
        },
      },
    },
  },
});
