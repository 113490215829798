import { Button } from "swash/Button";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";

import { ArticleEventDispatcherConfirmDialog } from "./ArticleEventDispatcherConfirmDialog";

export const EventDispatcherButton = ({
  eventDispatcher,
  disabled,
  label,
  articleId,
  scale,
}) => {
  const dialog = useDialogStore();
  return (
    <>
      <DialogDisclosure
        render={<Button scale={scale} />}
        disabled={disabled}
        store={dialog}
      >
        {label}
      </DialogDisclosure>
      <ArticleEventDispatcherConfirmDialog
        store={dialog}
        eventDispatcher={eventDispatcher}
        disabled={disabled}
        articleId={articleId}
      />
    </>
  );
};
