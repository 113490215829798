import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { useToaster } from "swash/Toast";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useListReadOnly } from "@/containers/list/List";
import { ArticleAuthorsSelect_articleFragment } from "@/gql-types";

import {
  ArticleAuthor,
  ArticleAuthorsButton,
  ArticleAuthorsSelector,
  ArticleFragment,
  useArticleAuthorsSelectState,
} from "./ArticleAuthorsSelect";

const Mutation = gql`
  mutation ArticleAuthorsSelect_updateArticle(
    $articleId: Int!
    $signatures: [SignatureInput!]!
  ) {
    updateArticle(input: { id: $articleId, signatures: $signatures }) {
      id
      ...ArticleAuthorsSelect_article
    }
  }
  ${ArticleFragment}
`;

export type ArticleAuthorsEditProps = {
  article: ArticleAuthorsSelect_articleFragment;
};

export const ArticleAuthorsEdit = (props: ArticleAuthorsEditProps) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));
  const [updateArticleAuthors] = useMutation(Mutation);
  const toaster = useToaster();
  const readOnly = useListReadOnly();
  const handleChange = useEventCallback((value: ArticleAuthor[]) => {
    updateArticleAuthors({
      variables: {
        articleId: props.article.id,
        signatures: value.map((author) => ({
          authorId: author.id,
          origin: author.origin,
        })),
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateArticle: {
          __typename: "Article",
          ...props.article,
          authors: value,
        },
      },
    }).catch(() => {
      toaster.danger("La mise à jour du signataire a échoué");
    });
  });

  if (!readOnly && active) {
    return (
      <ArticleAuthorsSelector
        article={props.article}
        value={props.article.authors}
        onChange={handleChange}
        required
      />
    );
  }
  return (
    <ArticleAuthorsButton
      authors={props.article.authors}
      onFocus={activate}
      onMouseEnter={activate}
      disabled={readOnly}
    />
  );
};

ArticleAuthorsEdit.fragments = {
  article: gql`
    fragment ArticleAuthorsEdit_article on Article {
      ...ArticleAuthorsSelect_article
    }
    ${useArticleAuthorsSelectState.fragments.article}
  `,
};
