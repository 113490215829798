import { gql } from "@apollo/client";
import { FORM_ERROR } from "final-form";
import { Button } from "swash/Button";
import { Dialog } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useStoreState } from "swash/utils/useStoreState";

import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSafeMutation } from "@/containers/Apollo";

const DispatchEventArticleMutation = gql`
  mutation ArticleEventDispatcherSection_triggerEventDispatcher(
    $eventDispatcherId: Int!
    $articleId: Int!
  ) {
    triggerEventDispatcher(
      input: { id: $eventDispatcherId, resourceId: $articleId }
    )
  }
`;

export const ArticleEventDispatcherConfirmDialog = ({
  eventDispatcher,
  articleId,
  finalFocusRef,
  store,
}) => {
  const [triggerEventDispatcher] = useSafeMutation(
    DispatchEventArticleMutation,
    {
      variables: { eventDispatcherId: eventDispatcher.id, articleId },
    },
  );

  const open = useStoreState(store, "open");

  if (!open) return null;

  const { name } = eventDispatcher;

  const handleSubmit = async () => {
    try {
      await triggerEventDispatcher();
      store.hide();
    } catch {
      return { [FORM_ERROR]: "Oups une erreur est survenue" };
    }
  };

  return (
    <Dialog store={store} finalFocusRef={finalFocusRef}>
      <Form onSubmit={handleSubmit}>
        <FormErrorToaster />
        <PanelHeader title={name} onClose={store.hide} />
        <PanelBody>
          Êtes-vous sûr d’effectuer l’action suivante sur « {name} » ?
        </PanelBody>
        <PanelFooter>
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={store.hide}
          >
            Annuler
          </Button>
          <FormSubmit>{eventDispatcher.buttonLabel}</FormSubmit>
        </PanelFooter>
      </Form>
    </Dialog>
  );
};
