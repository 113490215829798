import { forwardRef, memo } from "react";
import {
  IoAlbums,
  IoCloseCircle,
  IoCodeSlash,
  IoDownloadOutline,
  IoImages,
  IoLogoTwitter,
  IoVideocam,
  ZipFile,
} from "swash/Icon";
import { Loader } from "swash/Loader";
import { type VariantProps, cn, cva } from "swash/utils/classNames";

type MediaType = string;

const InnerDropTargetVariants = cva("", {
  variants: {
    scale: {
      xs: /* tw */ `min-h-12 data-[expandable]:data-[dropping]:min-h-36`,
      sm: /* tw */ `min-h-16 data-[expandable]:data-[dropping]:min-h-36`,
      md: /* tw */ `min-h-20 p-7 data-[expandable]:data-[dropping]:min-h-80`,
      lg: /* tw */ `min-h-32`,
    },
  },
});

export const InnerDropTarget = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & VariantProps<typeof InnerDropTargetVariants>
>(function InnerDropTarget({ scale, ...props }, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "group/DropTarget",
        "relative flex cursor-pointer items-start justify-center rounded-md bg-primary-bg-light p-4 text-center font-accent text-base text-primary-on transition-all",
        "data-[filled]:block data-[filled]:h-auto data-[filled]:cursor-default data-[filled]:bg-grey-bg-light data-[filled]:p-4 data-[filled]:text-left data-[filled]:text-dusk-on-strong",
        "data-[invalid]:bg-red-bg-light data-[invalid]:text-red-on",
        "data-[accept]:bg-green-bg-light data-[disabled]:stroke-grey-on data-[disabled]:text-green-on",
        "data-[disabled]:pointer-events-none data-[disabled]:cursor-default data-[disabled]:opacity-40",
        `[[data-display-mode="preview"]_&]:bg-grey-bg-light`,
        InnerDropTargetVariants({ scale }),
        props.className,
      )}
    />
  );
});

type DropTargetProps = React.ComponentProps<"div"> &
  VariantProps<typeof InnerDropTargetVariants> & {
    accept?: boolean;
    invalid?: boolean;
    filled?: boolean;
    expandable?: boolean;
    dropping?: boolean;
    disabled?: boolean;
    icon?: React.ComponentType;
    placeholder?: string;
    mediaTypes?: MediaType[];
    loading?: boolean;
    "data-field-control"?: boolean;
  };
export const DropTarget = forwardRef<HTMLDivElement, DropTargetProps>(
  function DropTarget(
    {
      accept,
      invalid,
      filled,
      expandable = true,
      dropping,
      disabled,
      children,
      icon,
      placeholder,
      mediaTypes,
      loading,
      ...props
    },
    ref,
  ) {
    const { icon: Icon, text } = getDropTargetTextAndIcon({
      accept,
      invalid,
      mediaTypes,
      placeholder,
    });

    return (
      <div
        className="relative"
        data-field-control={props["data-field-control"]}
      >
        {loading && <DropTargetLoader />}
        <InnerDropTarget
          ref={ref}
          {...props}
          data-testid="dnd-zone"
          data-accept={accept ? "" : undefined}
          data-invalid={invalid ? "" : undefined}
          data-expandable={expandable ? "" : undefined}
          data-dropping={dropping ? "" : undefined}
          data-filled={filled ? "" : undefined}
          data-disabled={disabled || loading ? "" : undefined}
        >
          <DropTargetBorder />
          {children ?? <DropTargetPlaceholder icon={Icon} text={text} />}
        </InnerDropTarget>
      </div>
    );
  },
);

type DropTargetPlaceholderProps = {
  icon: React.ComponentType;
  text: string;
};
export const DropTargetPlaceholder = memo(function DropTargetPlaceholder({
  icon: Icon,
  text,
}: DropTargetPlaceholderProps) {
  return (
    <div className="flex items-center gap-4">
      {Icon && <Icon />}
      <span>{text}</span>
    </div>
  );
});

export const DropTargetBorder = memo(function DropTargetBorder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        "pointer-events-none absolute inset-0 size-full rounded-md",
        "stroke-blue-border group-data-[accept]/DropTarget:stroke-green-border group-data-[filled]/DropTarget:stroke-grey-border group-data-[invalid]/DropTarget:stroke-red-border-strong",
      )}
    >
      <rect
        width="100%"
        height="100%"
        fill="none"
        strokeWidth="3"
        strokeDasharray="4, 7" // space between dashes
        strokeDashoffset="0"
        strokeLinecap="square"
        rx="6"
      />
    </svg>
  );
});

const DropTargetLoader = memo(function DropTargetLoader() {
  return (
    <div className="absolute inset-0 flex size-full items-center justify-center">
      <Loader size={24} />
    </div>
  );
});

export const getDropTargetTextAndIcon = ({
  accept,
  invalid,
  mediaTypes,
  placeholder = "Glisser...",
}: {
  accept?: boolean;
  invalid?: boolean;
  mediaTypes?: MediaType[];
  placeholder?: string;
}) => {
  if (accept) {
    return {
      text: "Déposer...",
      icon: IoDownloadOutline,
    };
  }
  if (invalid) {
    return {
      text: "Média non supporté",
      icon: IoCloseCircle,
    };
  }

  switch (true) {
    case mediaTypes && mediaTypes.length > 1:
      return {
        text: `Glisser ici ${generateMediaTypesPlaceholder(mediaTypes)}...`,
        icon: IoImages,
      };
    case mediaTypes?.includes("archives"):
      return {
        text: "Glisser ici les fichiers .zip depuis le volet d’enrichissements...",
        icon: ZipFile,
      };
    case mediaTypes?.includes("images"):
      return {
        text: "Glisser ici les images du portfolio...",
        icon: IoAlbums,
      };
    case mediaTypes?.includes("snippets"):
      return {
        text: "Glisser ici un snippet pour l’épingler...",
        icon: IoCodeSlash,
      };
    case mediaTypes?.includes("tweets"):
      return {
        text: "Glisser ici un tweet...",
        icon: IoLogoTwitter,
      };
    case mediaTypes?.includes("videos"):
      return {
        text: "Glisser ici une vidéo...",
        icon: IoVideocam,
      };
    default:
      return { text: placeholder, icon: IoDownloadOutline };
  }
};

const placeholderMap: Record<MediaType, string | undefined> = {
  images: "une image",
  products: "une fiche produit",
  products_summaries: "un récapitulatif produit",
  snippets: "un snippet",
  videos: "une vidéo",
};

const generateMediaTypesPlaceholder = (mediaTypes: MediaType[]) =>
  mediaTypes
    .sort()
    .map((mediaType) => placeholderMap[mediaType])
    .reduce<string[]>((acc, value, index) => {
      if (index !== mediaTypes.length - 1) {
        acc.push(value!);
      } else {
        acc.push(`ou ${value}`);
      }

      return acc;
    }, [])
    .join(", ");
