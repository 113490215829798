import { Dialog } from "swash/Dialog";
import { PanelBody, PanelHeader } from "swash/Panel";
import { useStoreState } from "swash/utils/useStoreState";

import { createRemoteDialog } from "@/components/RemoteDialog";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { Desync } from "@/containers/Ping";

import { GenericErrorBlock } from "../GenericErrorPage";
import { ImageForm } from "./ImageForm";

const DialogContent = ({ imageId, preview, editor, store, onDelete }) => {
  return (
    <ImageForm
      imageId={imageId}
      preview={preview}
      editor={editor}
      onDelete={onDelete}
    >
      {({ titleLink, actions, body }) => (
        <>
          <PanelHeader
            title={titleLink}
            actions={actions}
            onClose={store.hide}
          />
          <PanelBody style={{ padding: 0, overflow: "hidden" }}>
            {body}
          </PanelBody>
        </>
      )}
    </ImageForm>
  );
};

// when client is desynced, ie in that case when deleted media is still visible,
// open the image edit dialog will trigger an error,
// in that case we notify the user through the connection provider
const DESYNC_ERRORS = ["Invariant: missing article media"];

function isDesyncError(error) {
  return DESYNC_ERRORS.some((message) => error.message.includes(message));
}

function renderDialogError({ error }) {
  if (isDesyncError(error)) {
    return <Desync />;
  }
  return <GenericErrorBlock error={error} />;
}

export const ImageEditDialog = ({
  imageId,
  preview,
  editor,
  store,
  onDelete,
}) => {
  const open = useStoreState(store, "open");
  if (!open) return null;
  return (
    <Dialog
      store={store}
      data-testid="image-edit-dialog-body"
      style={{
        width: "100%",
        maxWidth: "1024px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      modal={false}
      portal
    >
      <ErrorBoundary render={renderDialogError}>
        <DialogContent
          imageId={imageId}
          preview={preview}
          editor={editor}
          store={store}
          onDelete={onDelete}
        />
      </ErrorBoundary>
    </Dialog>
  );
};

export const {
  RemoteDialogProvider: RemoteEditImageDialogProvider,
  RemoteDialogDisclosure: RemoteEditImageDialogDisclosure,
  useRemoteDialogContext: useEditImageRemoteDialogContext,
} = createRemoteDialog(ImageEditDialog);
