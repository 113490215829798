import { memo, useMemo } from "react";
import { useField } from "react-final-form";
import { cn } from "swash/utils/classNames";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";
import { EnumFieldCheckboxControl } from "@/containers/admin/CRUD/fields/EnumField";

export const getFormatters = ({ multi }) => {
  if (multi) {
    return {
      format: (value) => (value ? value.in : []),
      parse: (value) => (value && value.length ? { in: value } : null),
    };
  }
  return {
    format: (value) => (value ? value.eq : null),
    parse: (value) => (value ? { eq: value } : null),
  };
};

export const getFormattersV2 = ({ multi, items }) => {
  if (multi) {
    return {
      format: (value) =>
        value ? items.filter((i) => value.in.includes(i.value)) : [],
      parse: (value) =>
        value && value.length ? { in: value.map((v) => v.value) } : null,
    };
  }
  return {
    format: (value) => (value ? items.find((i) => i.value === value.eq) : null),
    parse: (value) => (value ? { eq: value.value } : null),
  };
};

const CheckboxFieldLabel = (props) => (
  <FieldLabel
    {...props}
    className={cn('after:content-[" :"]', props.className)}
  />
);

export function CheckboxEnumFilterField({
  name,
  label,
  enum: enumValues,
  multi = true,
  sortEntries = ([, labelA], [, labelB]) => labelA.localeCompare(labelB),
  w,
  scale,
  variant,
  ...others
}) {
  const field = useSelectField(name, {
    orientation: "horizontal",
    ...getFormatters({ multi }),
    ...others,
  });
  return (
    <FieldGroup {...field} className="mr-2">
      <CheckboxFieldLabel {...field}>{label}</CheckboxFieldLabel>
      <SelectField
        {...field}
        as={EnumFieldCheckboxControl}
        enum={enumValues}
        multi={multi}
        sortEntries={sortEntries}
        variant={variant}
      />
    </FieldGroup>
  );
}

export const SelectEnumFilterField = ({
  name,
  label,
  enum: unsortedItems,
  multi,
  disabled,
  clearable,
  labelSelector = (field) => field.label,
  labelElementSelector = (field) => field.label,
  iconSelector = () => null,
  sortEntries = (itemA, itemB) => itemA.label.localeCompare(itemB.label),
  className,
  ...others
}) => {
  const defaultValue = multi ? [] : null;

  const items = useMemo(
    () => unsortedItems.sort(sortEntries),
    [unsortedItems, sortEntries],
  );

  const {
    input: { value, onChange },
  } = useField(name, {
    ...getFormattersV2({ multi, items }),
    ...others,
  });

  const state = useEnumSelectState({
    title: label,
    value: value && items.length ? value : defaultValue,
    onChange,
    items,
    labelSelector,
    labelElementSelector,
    iconSelector,
    valueSelector: (field) => field.value?.toString(),
    disabledSelector: () => disabled,
    required: disabled || !clearable,
  });

  return (
    <div className={className}>
      <EnumSelect
        placeholder={label}
        aria-label={label}
        state={state}
        clearLabel="Vider"
      />
    </div>
  );
};

export const EnumFilterField = memo(({ appearance = "select", ...others }) => {
  switch (appearance) {
    case "select":
      return <SelectEnumFilterField {...others} />;
    case "checkbox":
      return <CheckboxEnumFilterField {...others} />;
    default:
      return null;
  }
});
