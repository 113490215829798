import React, { useEffect, useState } from "react";

import * as storage from "./localStorage";

export function useStorage<T>(
  key: string | null,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (key === null) return initialValue;
    return storage.getItem(key) ?? initialValue;
  });

  useEffect(() => {
    if (key === null || value === undefined) return;
    storage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}
