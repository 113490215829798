// @ts-check

/**
 * Get initials from a name.
 * @param {string} name
 * @returns {string}
 */
const getInitials = (name) => {
  const parts = name.split(/\s+/);
  return parts
    .map((part) => {
      const dashParts = part.split("-");
      return dashParts
        .filter(Boolean)
        .map((part) => `${part[0]}.`.toUpperCase())
        .join("-");
    })
    .join(" ");
};
exports.getInitials = getInitials;

/**
 * Get the name with initials as first name.
 * @param {string} name
 * @returns {string}
 */
const getNameWithInitials = (name) => {
  return name
    .trim()
    .split(" ")
    .map((name, index) => (index === 0 ? getInitials(name) : name))
    .join(" ");
};
exports.getNameWithInitials = getNameWithInitials;

/**
 * Get a signature from a list of names.
 * @param {string[]} names
 * @returns {string}
 */
const getSignature = (names) => {
  return names
    .map((name) => getNameWithInitials(name))
    .reduce((signature, name, index, all) => {
      const res = `${signature}${name}`;
      if (index < all.length - 2) return `${res}, `;
      if (index === all.length - 2) return `${res} et `;
      return res;
    }, "");
};
exports.getSignature = getSignature;
