import { gql } from "@apollo/client";
import { memo } from "react";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";
import { IoExitOutline } from "swash/Icon";
import { Link } from "swash/Link";

import { PreviewDialog } from "@/components/PreviewDialog";
import { useSafeQuery } from "@/containers/Apollo";

export { useDialogStore, DialogDisclosure };

const VideoQuery = gql`
  query VideoPreview_video($videoId: Int!) {
    video(id: $videoId) {
      id
      title
      playerUrl
      url
    }
  }
`;

const Video = memo(({ video }) => {
  return (
    <div className="relative aspect-video h-full w-auto max-w-full pb-8">
      <iframe
        title={video.title}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        src={video.playerUrl}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      <div className="flex gap-2 bg-white pt-2">
        <div className="flex-shrink-0 flex-grow basis-auto">{video.title}</div>
        <Link
          icon={<IoExitOutline />}
          className="shrink-0"
          href={video.url}
          target="_blank"
        >
          Ouvrir la vidéo
        </Link>
      </div>
    </div>
  );
});

function DialogContent({ videoId }) {
  const { data } = useSafeQuery(VideoQuery, {
    variables: { videoId },
    fetchPolicy: "cache-and-network",
  });
  if (!data) return null;
  return <Video video={data.video} />;
}

export function VideoPreviewDialog({ videoId, store }) {
  return (
    <PreviewDialog label="Prévisualisation de la vidéo" store={store}>
      <DialogContent videoId={videoId} />
    </PreviewDialog>
  );
}
