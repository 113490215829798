/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { x } from "@xstyled/styled-components";
import { EditorState } from "draft-js-es";
import { memo, useEffect, useMemo } from "react";
import { Select, SelectClear, SelectPopover } from "swash/Select";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useStoreState } from "swash/utils/useStoreState";
import { EnumSelectList, useEnumSelectState } from "swash/v2/EnumSelect";
import { RichSelectValue } from "swash/v2/RichSelect";

import { setBlockTypeAndStyle } from "../modifiers/setBlockTypeAndStyle";

export const name = "block-templates-control";

const BlockTemplatesSelector = memo(
  ({ blockTemplates, blockStyleName, disabled, refs }) => {
    const value = useMemo(
      () => blockTemplates.find((tpl) => tpl.name === blockStyleName) ?? null,
      [blockStyleName, blockTemplates],
    );

    const select = useEnumSelectState({
      items: blockTemplates ?? [],
      value,
      onChange: (blockTemplate) => {
        const { setEditorState } = refs.current;
        if (!blockTemplate) {
          setEditorState((editorState) =>
            setBlockTypeAndStyle(editorState, "paragraph"),
          );
          return;
        }

        setEditorState((editorState) =>
          setBlockTypeAndStyle(
            editorState,
            blockTemplate.editorType,
            blockTemplate.name ?? null,
          ),
        );
      },
      labelSelector: (tpl) => tpl.title,
      valueSelector: (tpl) => tpl.id,
    });

    const open = useStoreState(select.select, "open");

    useEffect(() => {
      const { lockFocus, unlockFocus, setEditorState } = refs.current;
      if (open) {
        // surround with setTimeout to ensure the editor state is updated
        setTimeout(() => {
          // force selection to flush the editor state
          setEditorState((editorState) =>
            EditorState.forceSelection(editorState, editorState.getSelection()),
          );
          // lock focus to prevent the editor from losing focus
          lockFocus();
        }, 0);
      } else unlockFocus();

      return () => {
        unlockFocus();
      };
    }, [open, refs]);

    if (!blockTemplates.length) return null;

    return (
      <>
        <Select
          store={select.select}
          disabled={disabled}
          scale="sm"
          aria-label="Styles"
          className="min-w-32"
          onMouseDown={(event) => {
            event.preventDefault();
            refs.current.lockFocus();
          }}
        >
          <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
            <RichSelectValue state={select} placeholder="Styles" />
          </div>
          <SelectClear
            store={select.select}
            onMouseDown={(event) => {
              event.preventDefault();
              event.stopPropagation();
              select.select.setValue("");
            }}
          />
        </Select>
        <SelectPopover store={select.select} modal aria-label="Styles">
          <EnumSelectList state={select} />
        </SelectPopover>
      </>
    );
  },
);

export const BlockControls = ({
  lockFocus,
  unlockFocus,
  hasFocus,
  editorState,
  setEditorState,
  blockTemplates,
  plugins,
  readOnly,
}) => {
  const refs = useLiveRef({
    lockFocus,
    unlockFocus,
    editorState,
    setEditorState,
  });
  const block = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey());

  const hasPlugin = useMemo(
    () => plugins.some((plugin) => plugin.name === "block-templates"),
    [plugins],
  );
  const enabledBlockTemplates = useMemo(
    () => blockTemplates.filter((tpl) => tpl.enabled),
    [blockTemplates],
  );

  return (
    <x.div display="flex" alignItems="center">
      <BlockTemplatesSelector
        blockTemplates={enabledBlockTemplates}
        blockStyleName={block?.getData().get("styleName") ?? null}
        disabled={!hasFocus || !hasPlugin || readOnly}
        refs={refs}
      />
    </x.div>
  );
};

BlockControls.group = "block-style";
BlockControls.width = 120;

export const checkIsControlVisible = ({ blockTemplates = [] }) =>
  blockTemplates.length > 0;
