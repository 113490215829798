import { gql } from "@apollo/client";
import { Button as AriakitButton } from "@ariakit/react";
import { memo } from "react";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";
import { useClipboard } from "use-clipboard-copy";

export type ArticleIdLabelProps = {
  article: {
    id: number;
  };
} & { className?: string };

type SpreadArticleIdLabelProps = ArticleIdLabelProps["article"] & {
  className?: string;
};

export const SpreadArticleIdLabel = memo<SpreadArticleIdLabelProps>(
  ({ id, className }) => {
    const clipboard = useClipboard({
      copiedTimeout: 1000,
    });
    return (
      <Tooltip tooltip={clipboard.copied ? "Copié !" : "Copier l’identifiant"}>
        <AriakitButton
          render={<span />}
          className={cn(
            className,
            "select-none font-accent tabular-nums hover:text-dusk-on",
          )}
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            clipboard.copy(String(id));
          }}
        >
          #{id}
        </AriakitButton>
      </Tooltip>
    );
  },
);

export const ArticleIdLabel = ({ article, className }: ArticleIdLabelProps) => {
  return <SpreadArticleIdLabel id={article.id} className={className} />;
};

ArticleIdLabel.fragments = {
  article: gql`
    fragment ArticleIdLabel_article on Article {
      id
    }
  `,
};
