import { useMemo } from "react";
import { useStorage } from "swash/utils/useStorage";

import { AgencyFilter } from "@/containers/image/filters/AgencyFilter";
import { ExpiredFilter } from "@/containers/image/filters/ExpiredFilter";
import { MediaFilter } from "@/containers/image/filters/MediaFilter";
import { PrintAuthorizedFilter } from "@/containers/image/filters/PrintAuthorizedFilter";
import { ShootingDateFilter } from "@/containers/image/filters/ShootingDateFilter";
import { WebAuthorizedFilter } from "@/containers/image/filters/WebAuthorizedFilter";
import { useSearchFormState } from "@/containers/search/SearchForm";

export const useImageSearchFormState = (name, { context } = {}) => {
  const [layoutMode, setLayoutMode] = useStorage(`${name}-layout-mode`, "grid");

  const searchConfig = useMemo(() => {
    const base = {
      name,
      filters: [
        AgencyFilter,
        MediaFilter,
        ShootingDateFilter,
        PrintAuthorizedFilter,
        WebAuthorizedFilter,
        ExpiredFilter,
      ],
      layoutMode,
      onLayoutModeChange: setLayoutMode,
    };

    switch (context) {
      case "live":
        return {
          ...base,
          storage: null,
          enableSearchParams: false,
        };
      default:
        return base;
    }
  }, [name, context, layoutMode, setLayoutMode]);

  return useSearchFormState(searchConfig);
};
