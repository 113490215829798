/* eslint-disable no-param-reassign */
import { gql } from "@apollo/client";
import styled, { x } from "@xstyled/styled-components";
import {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Popover, PopoverDisclosure, usePopoverStore } from "swash/Popover";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import { useStorage } from "swash/utils/useStorage";
import { useStoreState } from "swash/utils/useStoreState";

import { Image } from "@/components/Image";
import { TempOmega } from "@/components/icons";
import { useSafeQuery } from "@/containers/Apollo";

import { RichEditorToolbarButton } from "../../RichEditorToolbar";
import { insertOrReplaceCharacters } from "../../modifiers/insertOrReplaceCharacters";
import CHARACTERS from "./characters";

export const name = "special-characters-control";
export const label = "Insérer un caractère spécial";

const EmojisQuery = gql`
  query RichEditorSpecialCharactersPlugin_emojis {
    emojis(where: { enabled: true }) {
      nodes {
        id
        name
        title
        displayImage: encodedImage
      }
    }
  }
`;

const GroupTitle = styled.h3`
  background-color: white;
  position: sticky;
  top: 0;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.2;
  font-size: sm;
  padding: 2;
  margin: 0;
`;

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1;
`;

const Char = styled.button`
  height: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: base;
  background: transparent;
  border-width: 0;
  font-size: lg;
  &:focus {
    outline: 0;
  }
  &:hover,
  &:focus-visible {
    background-color: grey-lighter;
  }
`;

export const HISTORY_MAX_LENGTH = 200;
const MAX_FREQUENTLY_USED = 30;

const CHARACTERS_BY_CHAR = CHARACTERS.flatMap(
  (group) => group.characters,
).reduce((indexed, character) => {
  indexed[character.char] = character;
  return indexed;
}, {});

export const useFrequentlyUsed = (characters) => {
  return useMemo(() => {
    const stats = characters.reduce((stats, character) => {
      stats[character.char] = (stats[character.char] ?? 0) + 1;
      return stats;
    }, {});
    return Object.entries(stats)
      .sort(([, aCount], [, bCount]) => bCount - aCount)
      .map(([char]) => CHARACTERS_BY_CHAR[char])
      .filter(Boolean)
      .slice(0, MAX_FREQUENTLY_USED);
  }, [characters]);
};

const CharactersGroup = memo(({ characters, onSelect, onInsert }) => {
  return (
    <Group>
      {characters.map((character, index) => {
        const handleSelect = () => onSelect(character);
        return (
          <Char
            aria-label={character.title ?? character.name}
            key={index}
            type="button"
            onMouseEnter={handleSelect}
            onMouseLeave={() => onSelect(null)}
            onFocus={handleSelect}
            onClick={() => onInsert(character)}
            style={{
              width: character.displayImage ? "auto" : 40,
              padding: character.displayImage ? 6 : "unset",
            }}
          >
            {character.displayImage ? (
              <Image src={character.displayImage} className="h-5" />
            ) : (
              (character.display ?? character.char)
            )}
          </Char>
        );
      })}
    </Group>
  );
});

function SpecialCharSelector({ onInsert, frequentlyUsed }) {
  const [selected, setSelected] = useState(null);
  const onInsertRef = useLiveRef(onInsert);
  const handleInsert = useCallback(
    (character) => onInsertRef.current(character),
    [onInsertRef],
  );
  const { data } = useSafeQuery(EmojisQuery);

  return (
    <x.div
      w={328}
      h={328}
      display="grid"
      gridTemplateRows="1fr auto"
      borderRadius="base"
      overflow="hidden"
    >
      <x.div overflow="auto" position="relative">
        <GroupTitle>Fréquemment utilisés</GroupTitle>
        {frequentlyUsed.length === 0 ? (
          <x.div p={1} textAlign="center" color="on-light">
            Aucun caractère utilisé
          </x.div>
        ) : (
          <CharactersGroup
            characters={frequentlyUsed}
            onSelect={setSelected}
            onInsert={handleInsert}
          />
        )}
        {data?.emojis.nodes.length > 0 && (
          <>
            <GroupTitle>Émoticônes personnalisées</GroupTitle>
            <CharactersGroup
              characters={data.emojis.nodes}
              onSelect={setSelected}
              onInsert={(emoji) => handleInsert({ char: `:${emoji.name}:` })}
            />
          </>
        )}
        {CHARACTERS.map((group, index) => (
          <Fragment key={index}>
            <GroupTitle>{group.name}</GroupTitle>
            <CharactersGroup
              characters={group.characters}
              onSelect={setSelected}
              onInsert={handleInsert}
            />
          </Fragment>
        ))}
      </x.div>
      <x.div
        display="flex"
        gap={2}
        alignItems="center"
        borderTop={1}
        borderColor="layout-border"
        p={2}
      >
        <x.div
          fontSize={28}
          h={32}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {selected?.displayImage ? (
            <Image src={selected.displayImage} className="h-5" />
          ) : (
            (selected?.display ?? selected?.char)
          )}
        </x.div>
        <x.div>
          <x.div>{selected?.title}</x.div>
          <x.div>
            {selected?.title ? `:${selected.name}:` : selected?.name}
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
}

function SpecialCharPopover({
  store,
  onInsert,
  frequentlyUsed,
  getAnchorRect,
  open,
}) {
  return (
    <Popover
      store={store}
      modal
      getAnchorRect={getAnchorRect}
      style={{
        height: 328,
        width: 328,
      }}
      aria-label={label}
    >
      {open ? (
        <>
          <SpecialCharSelector
            onInsert={onInsert}
            frequentlyUsed={frequentlyUsed}
          />
        </>
      ) : null}
    </Popover>
  );
}

const CharacterToolbarButton = memo(({ character, onInsert, disabled }) => {
  return (
    <RichEditorToolbarButton
      label={`Insérer un « ${character.name} »`}
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();
        onInsert(character);
      }}
    >
      {character.display || character.char}
    </RichEditorToolbarButton>
  );
});

const InsertCharacterToolbarButton = memo(
  forwardRef(({ lockFocus, as, ...props }, ref) => {
    const defaultProps = {
      ref,
      label,
      ...props,
      onMouseDown: (event) => {
        lockFocus();
        props.onMouseDown(event);
      },
    };

    return (
      <RichEditorToolbarButton {...defaultProps} as={as}>
        <TempOmega />
      </RichEditorToolbarButton>
    );
  }),
);

const InsertSpecialCharButton = ({
  popover,
  lockFocus,
  hasFocus,
  as,
  ...props
}) => {
  return (
    <PopoverDisclosure
      store={popover}
      render={
        <InsertCharacterToolbarButton
          lockFocus={lockFocus}
          disabled={!hasFocus}
          as={as}
        />
      }
      {...props}
    />
  );
};

const SpecialCharButton = (props) => {
  const { lockFocus, unlockFocus, disabledWithoutFocus, as, menuPopover } =
    props;

  const propsRef = useLiveRef(props);

  const [history, setHistory] = useStorage(
    "editor-special-characters-history",
    [],
  );
  const frequentlyUsed = useFrequentlyUsed(history);
  const popover = usePopoverStore();
  const open = useStoreState(popover, "open");
  const popoverRef = useLiveRef(popover);
  const [hidePopover, setHidePopover] = useState(false);

  const previousOpen = usePrevious(open);

  useEffect(() => {
    if (previousOpen && !open) {
      unlockFocus();
    }
  }, [unlockFocus, open, previousOpen]);

  useLayoutEffect(() => {
    if (
      propsRef.current.onOpenAction &&
      previousOpen !== undefined &&
      previousOpen !== open
    ) {
      propsRef.current.onOpenAction(open);
    }
  }, [open, previousOpen, propsRef]);

  const handleInsert = useCallback(
    (character) => {
      const { setEditorState, editorState } = propsRef.current;
      setEditorState(insertOrReplaceCharacters(editorState, character.char));
      setHistory((history) =>
        [character, ...history].slice(0, HISTORY_MAX_LENGTH),
      );

      if (open) {
        setHidePopover(true);
      }
    },
    [setHistory, propsRef, open],
  );

  useEffect(() => {
    const popover = popoverRef.current;
    if (hidePopover) {
      popover.hide();
      setHidePopover(false);
      setTimeout(() => unlockFocus(), 0);
    }
  }, [hidePopover, popoverRef, unlockFocus]);

  return (
    <>
      <InsertSpecialCharButton
        hasFocus
        popover={popover}
        lockFocus={lockFocus}
        disabledWithoutFocus={disabledWithoutFocus}
        as={as}
      />
      <SpecialCharPopover
        store={popover}
        getAnchorRect={
          menuPopover
            ? () =>
                menuPopover
                  ?.getState()
                  ?.anchorElement?.getBoundingClientRect?.() ?? null
            : null
        }
        open={open}
        onInsert={handleInsert}
        frequentlyUsed={frequentlyUsed}
      />
    </>
  );
};
export const ButtonCommand = SpecialCharButton;
export const BlockControls = (props) => {
  const { hasFocus, columnGap } = props;
  const propsRef = useLiveRef(props);
  const [history, setHistory] = useStorage(
    "editor-special-characters-history",
    [],
  );
  const frequentlyUsed = useFrequentlyUsed(history);
  const mostUsedCharacter = frequentlyUsed[0];
  const handleInsert = useCallback(
    (character) => {
      const { setEditorState, editorState } = propsRef.current;
      setEditorState(insertOrReplaceCharacters(editorState, character.char));
      setHistory((history) =>
        [character, ...history].slice(0, HISTORY_MAX_LENGTH),
      );
    },
    [setHistory, propsRef],
  );
  return (
    <x.div display="flex" columnGap={columnGap}>
      <SpecialCharButton {...props} />
      {mostUsedCharacter && (
        <CharacterToolbarButton
          character={mostUsedCharacter}
          onInsert={handleInsert}
          disabled={!hasFocus}
        />
      )}
    </x.div>
  );
};
BlockControls.group = "insert";
BlockControls.buttonsCount = 2;
