import { memo } from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Button } from "swash/Button";
import { DialogLayout } from "swash/DialogLayout";
import { IoAlertCircleOutline } from "swash/Icon";
import { PanelBody } from "swash/Panel";

import { Backdrop } from "@/components/Backdrop";

export const NotFound = memo(() => {
  return (
    <>
      <Backdrop />
      <DialogLayout style={{ width: 800 }} className="py-8">
        <PanelBody className="flex flex-col items-center justify-center">
          <IoAlertCircleOutline size={27} className="mb-4 inline-block" />
          <div className="mb-8 font-accent text-3xl font-semibold">
            Page introuvable
          </div>
          <div className="mb-8 h-1 w-7 bg-blue-bg-light" />
          <div className="mb-4 font-medium">
            La page que vous recherchez n’existe pas.
          </div>
          <div className="mb-4">
            <Button asChild>
              <ReactRouterLink to="/">Retour à l’accueil</ReactRouterLink>
            </Button>
          </div>
        </PanelBody>
      </DialogLayout>
    </>
  );
});
