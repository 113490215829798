import { Dialog } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useStoreState } from "swash/utils/useStoreState";

import { createRemoteDialog } from "@/components/RemoteDialog";

import { ImageMultipleForm } from "./ImageMultipleForm";

const DialogContent = ({ imageIds, onCloseImportDialog, store, ...props }) => {
  return (
    <ImageMultipleForm
      imageIds={imageIds}
      onCloseImportDialog={onCloseImportDialog}
      store={store}
      {...props}
    >
      {({ title, body, footer }) => (
        <>
          <PanelHeader title={title} onClose={store.hide} />
          <PanelBody style={{ maxHeight: 458 }}>{body}</PanelBody>
          <PanelFooter>{footer}</PanelFooter>
        </>
      )}
    </ImageMultipleForm>
  );
};

const ImageEditMultipleDialog = ({
  imageIds,
  onCloseImportDialog,
  store,
  ...props
}) => {
  const open = useStoreState(store, "open");
  if (!open) return null;
  return (
    <Dialog
      store={store}
      aria-label="Éditer les images"
      style={{
        width: "100%",
        maxWidth: "720px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      modal={false}
      portal
    >
      <DialogContent
        imageIds={imageIds}
        onCloseImportDialog={onCloseImportDialog}
        store={store}
        {...props}
      />
    </Dialog>
  );
};

export const {
  RemoteDialogProvider: RemoteEditImageMultipleDialogProvider,
  RemoteDialogDisclosure: RemoteEditImageMultipleDialogDisclosure,
  useRemoteDialogContext: useEditImageRemoteMultipleDialogContext,
} = createRemoteDialog(ImageEditMultipleDialog);
