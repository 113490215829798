import { gql } from "@apollo/client";
import { useCallback, useMemo } from "react";

import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";

// Using this trick we ignore checking with ESLint
const gqlClient = gql;

export const expandedPublicationColumnsQuery = gqlClient`
  query ExpandPublicationColumnsQuery {
    expandedPublicationColumns @client {
      id
      isExpanded
    }
  }
`;

export const useExpandedColumns = () => {
  const { data } = useSafeQuery(expandedPublicationColumnsQuery);
  const expandedPublicationColumns = useMemo(
    () => data?.expandedPublicationColumns,
    [data],
  );
  return {
    expandedPublicationColumns,
  };
};

export const withExpandedColumns = (Component) => (props) => {
  const { expandedPublicationColumns } = useExpandedColumns();
  return (
    <Component
      {...props}
      expandedPublicationColumns={expandedPublicationColumns}
    />
  );
};

const expandColumnMutation = gqlClient`
  mutation ExpandColumns($id: Int!, $value: Boolean!) {
    expandColumn(id: $id, value: $value) @client
  }
`;

export const useExpandColumn = () => {
  const [mutate] = useSafeMutation(expandColumnMutation);
  const expandColumn = useCallback(
    (id, value) => mutate({ variables: { id, value } }),
    [mutate],
  );
  return { expandColumn };
};

export const withExpandColumn = (Component) => (props) => {
  const { expandColumn } = useExpandColumn();
  return <Component {...props} expandColumn={expandColumn} />;
};
