import { gql } from "@apollo/client";
import moment from "moment";
import { Tooltip } from "swash/Tooltip";

import { Time } from "@/components/Time";
import { UserHoverCardAvatar } from "@/containers/user/UserHoverCard";

const Info = ({ children }) => {
  return (
    <span className="text-dusk-500 before:mx-2 before:content-['•']">
      {children}
    </span>
  );
};

export const ActivityTime = ({ date }) => {
  return (
    <Info>
      <Time date={date}>{moment(date).format("DD/MM/YYYY à HH:mm")}</Time>
    </Info>
  );
};

export const ModifyBy = ({ user, date }) => {
  return (
    <Info>
      <Tooltip
        tooltip={
          <>
            Modifié par {user} <Time date={date} />
          </>
        }
      >
        <span>Modifié</span>
      </Tooltip>
    </Info>
  );
};

export const ActivityItem = ({ user, children }) => {
  return (
    <div role="listitem" className="group flex w-full items-start gap-2">
      <UserHoverCardAvatar user={user} />
      {children}
    </div>
  );
};

ActivityItem.fragments = {
  activityItemAvatar: gql`
    fragment ActivityItemAvatarFragment on ActivityItem {
      user {
        id
        ...UserHoverCardAvatar_user
      }
    }
    ${UserHoverCardAvatar.fragments.user}
  `,
};
