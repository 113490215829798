import { gql } from "@apollo/client";
import { Button as AriakitButton } from "@ariakit/react";
import { cn } from "swash/utils/classNames";

import { CharCountEditor, useCharCountEditorState } from "./CharCountEditor";

export type SpreadArticleDescriptionSignsProps = {
  id: number;
  nbChar: number;
  nbCharMax: number;
  disabled: boolean;
};

export const SpreadArticleDescriptionSigns = ({
  id,
  nbChar,
  nbCharMax,
  disabled,
}: SpreadArticleDescriptionSignsProps) => {
  const state = useCharCountEditorState({ articleId: id });

  const {
    buttonProps: { className: buttonClassName, ...buttonProps },
  } = state;

  return (
    <AriakitButton
      render={<span />}
      disabled={disabled}
      className={cn(
        buttonClassName,
        "flex select-none whitespace-nowrap font-accent",
      )}
      {...buttonProps}
    >
      <CharCountEditor
        state={state}
        disabled={disabled}
        nbChar={nbChar}
        nbCharMax={nbCharMax}
      />
    </AriakitButton>
  );
};

export type ArticleDescriptionSignsProps = {
  article: {
    id: number;
    nbChar: number;
    nbCharMax: number;
  };
  disabled: boolean;
};

export const ArticleDescriptionSigns = ({
  article,
  disabled,
}: ArticleDescriptionSignsProps) => {
  return (
    <SpreadArticleDescriptionSigns
      id={article.id}
      nbChar={article.nbChar}
      nbCharMax={article.nbCharMax}
      disabled={disabled}
    />
  );
};

ArticleDescriptionSigns.fragments = {
  article: gql`
    fragment ArticleDescriptionSigns_article on Article {
      id
      nbChar
      nbCharMax
    }
  `,
};
