import { Dialog as AriakitDialog } from "@ariakit/react";
import { backdropProps } from "swash/Dialog";
import { cn } from "swash/utils/classNames";
import { useStoreState } from "swash/utils/useStoreState";

export function PreviewDialog({
  label,
  className,
  dialogCloseButtonRef,
  children,
  store,
}) {
  const open = useStoreState(store, "open");
  return (
    <AriakitDialog
      backdrop={<div className={backdropProps.className} />}
      store={store}
      className={cn(
        className,
        `fixed inset-0 z-dialog flex flex-col items-center justify-center overflow-hidden
          p-3 focus:outline-0`,
      )}
      aria-label={label}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          store.hide();
        }
      }}
      initialFocusRef={dialogCloseButtonRef}
    >
      {open ? children : null}
    </AriakitDialog>
  );
}
