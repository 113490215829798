import { Separator as AriakitSeparator } from "@ariakit/react";
import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

const InnerSeparator = ({ className }) => {
  return (
    <hr className={cn("m-0 border-t border-t-grey-border-light", className)} />
  );
};

export const Separator = forwardRef((props, ref) => {
  return <AriakitSeparator ref={ref} render={<InnerSeparator />} {...props} />;
});
