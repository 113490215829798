import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { PanelBody, PanelFooter } from "swash/Panel";
import { Popover } from "swash/Popover";
import { useStoreState } from "swash/utils/useStoreState";

import { CorrelateLabel } from "@/components/CorrelateLabel";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { RichEditorTextBoxField } from "@/components/fields/RichEditorTextBoxField";
import { useTextInputField } from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { muteEditorEvents } from "@/components/teleporters/EditorBlockCapsule";

const FIELD_NAME_TITLE = "title";
const FIELD_NAME_PREFIX = "prefix";

function TitleField({ articleTitle, checkTitleIsCorrelated }) {
  const form = useForm();
  const field = useTextInputField(FIELD_NAME_TITLE, {
    parse: (v) => v,
  });
  const {
    input: { value },
  } = field.state.field;

  const onCorrelate = () => form.change(FIELD_NAME_TITLE, articleTitle);

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>
        <CorrelateLabel
          onClick={onCorrelate}
          hidden={checkTitleIsCorrelated(value, articleTitle)}
          resourceLabelName="titre"
        >
          Titre de l’article lié
        </CorrelateLabel>
      </FieldLabel>
      <RichEditorTextBoxField {...field} w={1} />
    </FieldGroup>
  );
}

function PrefixField() {
  const field = useTextInputField(FIELD_NAME_PREFIX, {
    required: true,
    parse: (v) => v,
  });

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>Préfixe du lien</FieldLabel>
      <RichEditorTextBoxField {...field} w={1} />
    </FieldGroup>
  );
}

export const checkTitleIsCorrelated = (value, articleTitle) =>
  value === (articleTitle ?? "");

/**
 * @param {?} props
 */
export function EditArticleMetadataPopover({
  onSubmit,
  initialValues,
  articleTitle,
  store,
}) {
  const open = useStoreState(store, "open");

  return (
    <Popover
      store={store}
      aria-label="Édition du bloc article"
      style={{ width: 328 }}
      modal
      {...muteEditorEvents()}
    >
      {open ? (
        <Form
          onSubmit={(data) => {
            onSubmit(data, !checkTitleIsCorrelated(data.title, articleTitle));
            setTimeout(() => store.hide());
          }}
          initialValues={initialValues}
          aria-label="Édition du bloc article"
        >
          <FormErrorToaster />
          <PanelBody>
            <Fieldset>
              <FieldsetField>
                <PrefixField />
              </FieldsetField>
              <FieldsetField>
                <TitleField
                  articleTitle={articleTitle}
                  checkTitleIsCorrelated={checkTitleIsCorrelated}
                />
              </FieldsetField>
            </Fieldset>
          </PanelBody>
          <PanelFooter>
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={store.hide}
            >
              Annuler
            </Button>
            <FormSubmit>Enregistrer</FormSubmit>
          </PanelFooter>
        </Form>
      ) : null}
    </Popover>
  );
}
