import { gql, useMutation } from "@apollo/client";
import { forwardRef, memo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";
import { PanelSection } from "swash/Panel";
import { Tooltip } from "swash/Tooltip";
import { useStoreState } from "swash/utils/useStoreState";

import { ConfirmDialog, ConfirmDialogBody } from "@/components/ConfirmDialog";
import { useSafeQuery } from "@/containers/Apollo";
import { RouteError } from "@/containers/Router";
import { useImageSearchFormState } from "@/containers/image/ImageSearchForm";
import { query } from "@/containers/image/search/ImageListQuery";

import { useEditImageRemoteDialogContext } from "./ImageEditDialog";

const DeleteImageMutation = gql`
  mutation ImageForm_deleteImage($imageId: Int!) {
    deleteImage(input: { id: $imageId }) {
      id
    }
  }
`;

const ImageQuery = gql`
  query ImageDelete_image($imageId: Int!) {
    image(id: $imageId) {
      id
      used
    }
  }
`;

const InnerDeleteButton = forwardRef((props, ref) => {
  return (
    <Button className="w-44" variant="danger" ref={ref} {...props}>
      Supprimer l’image
    </Button>
  );
});

export const DeleteImageSection = memo(({ imageId, onDelete }) => {
  const dialog = useDialogStore();
  const { data } = useSafeQuery(ImageQuery, { variables: { imageId } });

  if (!data) return null;
  if (data && !data.image) {
    throw new RouteError("Image not found", 404);
  }

  return (
    <PanelSection className="flex justify-end gap-6">
      {data.image.used ? (
        <Tooltip
          tooltip={
            <>
              <div>
                Cette image est encore utilisée, afin de pouvoir la supprimer
              </div>
              <div>
                vous devez d’abord la retirer de toutes ses utilisations
              </div>
            </>
          }
        >
          <span>
            <InnerDeleteButton disabled />
          </span>
        </Tooltip>
      ) : (
        <DialogDisclosure store={dialog} render={<InnerDeleteButton />} />
      )}
      <DeleteImageDialog store={dialog} imageId={imageId} onDelete={onDelete} />
    </PanelSection>
  );
});

const DeleteImageDialog = ({ imageId, store, onDelete }) => {
  const [deleteImage] = useMutation(DeleteImageMutation, {
    variables: { imageId },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const imageDialog = useEditImageRemoteDialogContext();
  const imageDialogOpen = useStoreState(imageDialog?.store, "open");
  const { hide } = store;

  const searchForm = useImageSearchFormState();

  const handleConfirm = useCallback(async () => {
    await deleteImage({
      variables: { imageId },
      optimisticResponse: {
        __typename: "Mutation",
        deleteImage: {
          id: imageId,
        },
      },
      update: (cache, { data: { deleteImage } }) => {
        // update images list cache when deleting image in search images context
        // i.e. in search images page or when deleting in article with opened
        // search images panel
        const queryOptions = { query, variables: searchForm.variables };
        const data = cache.readQuery(queryOptions);
        if (!data) return;
        cache.writeQuery({
          ...queryOptions,
          data: {
            connection: {
              ...data.connection,
              nodes: data.connection.nodes.filter(
                ({ id }) => id !== deleteImage.id,
              ),
            },
          },
        });
      },
    });

    if (onDelete) onDelete();

    // hide delete dialog
    hide();

    // hide image dialog
    if (imageDialogOpen) {
      imageDialog.store.hide();
    }

    // redirect to search images if url matches /images/:id
    if (/\/images\/(\d+)/.test(location.pathname)) {
      navigate("/search/images");
    }
  }, [
    imageDialog,
    deleteImage,
    hide,
    onDelete,
    searchForm,
    imageId,
    location,
    navigate,
    imageDialogOpen,
  ]);

  return (
    <ConfirmDialog
      store={store}
      onConfirm={handleConfirm}
      title="Suppression d’image"
      confirmButtonLabel="Supprimer l’image"
    >
      <ConfirmDialogBody>
        Êtes-vous sûr de vouloir supprimer cette image ?
      </ConfirmDialogBody>
    </ConfirmDialog>
  );
};
