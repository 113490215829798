import { createElement } from "@ariakit/react-core/utils/system";
import { Options } from "@ariakit/react-core/utils/types";
import * as Sentry from "@sentry/browser";
import { useState } from "react";
import { Button } from "swash/Button";
import { IoCloudOffline } from "swash/Icon";
import { cn } from "swash/utils/classNames";

import { LogAmplitudeErrorDisplay } from "@/containers/Amplitude";

import { ErrorPage, ErrorPageTitle } from "./ErrorPage";

const debug = process.env["NODE_ENV"] !== "production";

const useIsOnlineAtMount = () => {
  const [onLine] = useState(navigator.onLine);
  return onLine;
};

type DebugMessageProps = {
  error: Error;
};
function DebugMessage({ error }: DebugMessageProps) {
  return (
    debug && (
      <div
        style={{
          backgroundColor: "#eee",
          padding: 16,
          margin: "32px 0",
          overflowX: "auto",
        }}
      >
        <strong>{error.message}</strong>
        <pre style={{ margin: 0, padding: 0, whiteSpace: "pre-wrap" }}>
          {error.stack}
        </pre>
      </div>
    )
  );
}

type ReportErrorButtonProps = {
  eventId?: string;
};
export function ReportErrorButton({ eventId }: ReportErrorButtonProps) {
  return (
    <Button
      type="button"
      variant="secondary"
      appearance="text"
      onClick={() => {
        Sentry.showReportDialog({
          lang: "fr",
          title: "Un problème est survenu",
          eventId,
        });
      }}
    >
      Signaler le problème
    </Button>
  );
}

type GenericErrorControlsProps = {
  error: Error;
  eventId?: string;
  retry: () => void;
  mode?: "retry" | "reload";
  className?: string;
};
export function GenericErrorControls({
  error,
  eventId,
  retry,
  mode = "reload",
  className,
}: GenericErrorControlsProps) {
  const onLine = useIsOnlineAtMount();
  return (
    <>
      <div className={cn("flex gap-2", className)}>
        {(() => {
          switch (mode) {
            case "retry":
              return (
                <Button
                  type="button"
                  onClick={() => {
                    retry();
                  }}
                >
                  Recharger l’élément
                </Button>
              );
            case "reload":
            default:
              return (
                <Button
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Recharger la page
                </Button>
              );
          }
        })()}
        {onLine && <ReportErrorButton eventId={eventId} />}
      </div>
      <DebugMessage error={error} />
    </>
  );
}

const ErrorMessageParagraph = (props: React.ComponentProps<"p">) => (
  <p {...props} className={cn("m-0 mb-2", props.className)} />
);

type ErrorMessageProps = Options;
export function ErrorMessage(props: ErrorMessageProps) {
  const onLine = useIsOnlineAtMount();
  return createElement(ErrorMessageParagraph, {
    ...props,
    children: onLine ? (
      <>
        <span role="img" aria-label="Triste">
          😰
        </span>{" "}
        Désolé, une erreur est survenue !
      </>
    ) : (
      <>
        <IoCloudOffline className="align-sub" /> Vous n’êtes pas connecté à
        internet
      </>
    ),
  });
}

type ErrorBlockProps = React.ComponentProps<"div">;
export const ErrorBlock = ({ className, ...props }: ErrorBlockProps) => (
  <div className={cn("p-2 text-center", className)} {...props} />
);

type GenericErrorBlockProps = {
  error: Error;
  eventId?: string;
  retry: () => void;
  mode?: "retry" | "reload";
};
export function GenericErrorBlock({
  error,
  eventId,
  retry,
  mode,
}: GenericErrorBlockProps) {
  return (
    <ErrorBlock>
      <LogAmplitudeErrorDisplay errorType="block" error={error} />
      <ErrorMessage />
      <GenericErrorControls
        error={error}
        eventId={eventId}
        className="justify-center"
        mode={mode}
        retry={retry}
      />
    </ErrorBlock>
  );
}

type GenericErrorPageProps = {
  error: Error;
  eventId?: string;
  retry: () => void;
  mode?: "retry" | "reload";
};
export function GenericErrorPage({
  error,
  eventId,
  mode,
  retry,
}: GenericErrorPageProps) {
  const onLine = useIsOnlineAtMount();
  return (
    <ErrorPage showDragon={onLine}>
      <LogAmplitudeErrorDisplay errorType="page" error={error} />
      <ErrorMessage render={ErrorPageTitle} />
      <GenericErrorControls
        error={error}
        eventId={eventId}
        mode={mode}
        retry={retry}
      />
    </ErrorPage>
  );
}
