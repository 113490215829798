import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

export const PANEL_WIDTH = 400;
export const NAVBAR_WIDTH = 48;

export const ArticleResizeContainer = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(function ArticleResizeContainer(props, ref) {
  return (
    <div
      {...props}
      ref={ref}
      className={cn("group/ArticleResizeContainer", props.className)}
    />
  );
});

export const ArticleContainer = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(function ArticleContainer(props, ref) {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        "mx-5 w-auto max-w-[640px] px-6",
        "desktop-md:mx-auto",
        "desktop-lg:w-[calc(theme(size.article-width)+2*theme(space.6))] desktop-lg:max-w-none desktop-lg:px-6",
        "print:w-auto",
        'group-data-[device="mobile"]/ArticleResizeContainer:mx-auto group-data-[device="mobile"]/ArticleResizeContainer:max-w-[360px]',
        props.className,
      )}
    />
  );
});

export const ArticleContent = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(function ArticleContent(props, ref) {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        "shadow-article-content relative z-[2] flex flex-col bg-white",
        'data-[focus-mode="true"]:h-screen',
        '[[data-display-mode="preview"]_&]:bg-grey-100 [[data-display-mode="preview"]_&]:shadow-none',
        '[[data-display-mode="compare"]_&]:bg-grey-50 [[data-display-mode="preview"]_&]:shadow-none',
        props.className,
      )}
    />
  );
});
