import { gql } from "@apollo/client";
import { Tooltip } from "swash/Tooltip";

import { EventStatusIcon } from "./EventStatusIcon";
import { EventStatusList, EventStatusListProps } from "./EventStatusList";

type EventStatus = EventStatusListProps["event"]["statuses"][number];

type Event = {
  status: "queued" | "in_progress" | "failure" | "timed_out" | "success";
  statuses: EventStatus[];
};

type StatusListIndicatorProps = {
  status: Event["status"];
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
};

const StatusListText = (props: StatusListIndicatorProps) => {
  return (
    <div className="flex items-center gap-1">
      <div className="inline-flex align-[-15%] text-sm">
        <EventStatusIcon status={props.status} />
      </div>
      {props.children}
    </div>
  );
};

export const StatusListIndicator = (props: StatusListIndicatorProps) => {
  const hasFailedDetailed =
    ["failure", "timed_out"].includes(props.status) && props.children;
  return (
    <Tooltip tooltip={props.tooltip}>
      <div className="w-fit">
        <StatusListText status={props.status}>{props.children}</StatusListText>
        {hasFailedDetailed && (
          <div className="text-xs text-danger-on">
            Survolez pour le détail de l’erreur
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export type EventStatusIndicatorProps = {
  event: Event;
  children?: React.ReactNode;
};

export const EventStatusIndicator = (props: EventStatusIndicatorProps) => {
  if (props.event.statuses.length === 0) {
    return (
      <StatusListText status={props.event.status}>
        {props.children}
      </StatusListText>
    );
  }
  return (
    <StatusListIndicator
      status={props.event.status}
      tooltip={<EventStatusList event={props.event} />}
    >
      {props.children}
    </StatusListIndicator>
  );
};

EventStatusIndicator.fragments = {
  event: gql`
    fragment EventStatusIndicator_event on Event {
      status
      statuses {
        id
      }
      ...EventStatusList_event
    }

    ${EventStatusList.fragments.event}
  `,
};
