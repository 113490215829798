/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { gql } from "@apollo/client";
import { memo } from "react";
import { createTeleporter } from "react-teleporter";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";
import { IoCrop } from "swash/Icon";
import { PageLoader } from "swash/Loader";

import { ImageFluidFragment } from "@/components/Image";
import { useSafeQuery } from "@/containers/Apollo";
import { ImageCropPreview } from "@/containers/image/ImageCropPreview";

import { RouteError } from "../Router";
import { ImageCropDialog } from "./ImageCropDialog";

const ArticleMediasQuery = gql`
  query ArticleImagePreview_medias($articleId: Int!, $imageId: Int) {
    image(id: $imageId) {
      id
      articleMedias(where: { articleId: { eq: $articleId } }) {
        id
        defaultCropRegion {
          top
          left
          width
          height
        }
        media {
          id
          ... on Image {
            width
            height
            articleImagePreviewImgProps: fluid(maxWidth: 600) {
              ...ImageFluidFragment
            }
          }
        }
      }
    }
  }
  ${ImageFluidFragment}
`;

export const ArticleImagePreview = memo(({ articleId, imageId }) => {
  const { data } = useSafeQuery(ArticleMediasQuery, {
    variables: { articleId, imageId },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  if (!data) return <PageLoader />;
  if (!data.image) return null;

  if (!data.image) {
    throw new RouteError("Image not found", 404);
  }

  const [articleMedia] = data.image.articleMedias;

  if (!articleMedia) {
    throw new Error(`Invariant: missing article media`);
  }

  if (!articleMedia.defaultCropRegion) return null;

  return (
    <ArticleImagePreviewImage
      articleMediaId={articleMedia.id}
      image={articleMedia.media}
      region={articleMedia.defaultCropRegion}
    />
  );
});

export const ArticleImageDisclosureTeleporter = createTeleporter();

const ArticleImagePreviewImage = ({ image, region, articleMediaId }) => {
  return (
    <>
      <ArticleImageDisclosureTeleporter.Source>
        <ArticleImagePreviewDisclosure articleMediaId={articleMediaId} />
      </ArticleImageDisclosureTeleporter.Source>
      <ImageCropPreview
        imgProps={{
          ...image.articleImagePreviewImgProps,
          style: { maxHeight: 522 },
        }}
        imgSize={{ width: image.width, height: image.height }}
        region={region}
      />
    </>
  );
};

const ArticleImagePreviewDisclosure = ({ articleMediaId }) => {
  const dialog = useDialogStore();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DialogDisclosure
        store={dialog}
        render={
          <Button
            type="button"
            appearance="text"
            className="px-2"
            variant="secondary"
          >
            <IoCrop />
            Cadrages
          </Button>
        }
      />
      <ImageCropDialog store={dialog} articleMediaId={articleMediaId} />
    </div>
  );
};
