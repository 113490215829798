import { Portal } from "@ariakit/react";
import React, { useEffect } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import type { ButtonProps } from "../Button";
import { useOptionalDialogApi } from "../Dialog";
import { BaseToastProps, Toast, ToastVariant } from "./Toast";
import { ToastContainer } from "./ToastContainer";

export interface BaseToast {
  id: string;
  dismiss: () => void;
}

export interface ToastOptions {
  dismissDelay?: number;
  level?: "critical" | "minor";
  closable?: boolean;
  action?: (props: { buttonProps: ButtonProps }) => React.ReactNode;
  detail?: React.ReactNode;
}

interface ToastContextOptions extends ToastOptions {
  variant: ToastVariant;
}

type ToastContextValue = {
  showToast: (
    message: string | React.ReactNode,
    options: ToastContextOptions,
  ) => BaseToast;
};

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

const getRandomId = () => String(Math.random().toString(36).slice(2, 9));

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const dialogApi = useOptionalDialogApi();
  const [toasts, setToasts] = useState<BaseToastProps[]>([]);

  const showToast = useCallback(
    (
      message: string | React.ReactNode,
      {
        variant,
        level,
        closable,
        dismissDelay,
        action,
        detail,
      }: ToastContextOptions,
    ): BaseToast => {
      if (level === "critical") {
        setToasts((toasts) => {
          return toasts.map((toast) =>
            toast.level === level ? { ...toast, visible: false } : toast,
          );
        });
      }

      // Add toast.
      const id = getRandomId();
      const toast = {
        id,
        message,
        variant,
        closable,
        level,
        dismissDelay,
        visible: true,
        action,
        detail,
        dismiss: () => {
          setToasts((toasts) =>
            toasts.map((toast) =>
              toast.id === id ? { ...toast, visible: false } : toast,
            ),
          );
        },
        remove: () => {
          setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
        },
      };

      setToasts((toasts) =>
        toast.level === "minor" ? [...toasts, toast] : [toast, ...toasts],
      );

      return { id: toast.id, dismiss: toast.dismiss };
    },
    [],
  );

  useEffect(() => {
    if (!dialogApi) return;
    if (toasts.length > 0) {
      return dialogApi.disableModal();
    }
    return;
  }, [toasts, dialogApi]);

  const value = useMemo(() => ({ showToast }), [showToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Portal>
        <ToastContainer position="top">
          {toasts
            .filter((toast) => toast.level === "critical")
            .map((toast) => (
              <Toast key={toast.id} toast={toast} />
            ))}
        </ToastContainer>
        <ToastContainer position="bottom">
          {toasts
            .filter((toast) => toast.level === "minor")
            .map((toast) => (
              <Toast key={toast.id} toast={toast} />
            ))}
        </ToastContainer>
      </Portal>
    </ToastContext.Provider>
  );
};

type ToasterFunctionType = (
  message: string | React.ReactNode,
  options?: ToastOptions,
) => BaseToast;

export type Toaster = {
  success: ToasterFunctionType;
  warning: ToasterFunctionType;
  danger: ToasterFunctionType;
  info: ToasterFunctionType;
};

const defaultOptions: ToastOptions = {
  dismissDelay: 4000,
  level: "minor",
  closable: true,
};

export const useToaster = () => {
  const ctx = useContext(ToastContext);

  if (!ctx) {
    throw Error("The `useToaster` hook must be called with a `ToastProvider`.");
  }

  const toaster: Toaster = useMemo(
    () => ({
      success: (message, options = defaultOptions) =>
        ctx.showToast(message, {
          ...defaultOptions,
          ...options,
          variant: "success",
        }),
      warning: (message, options = defaultOptions) =>
        ctx.showToast(message, {
          ...defaultOptions,
          ...options,
          variant: "warning",
        }),
      danger: (message, options = defaultOptions) =>
        ctx.showToast(message, {
          ...defaultOptions,
          ...options,
          variant: "danger",
        }),
      info: (message, options = defaultOptions) =>
        ctx.showToast(message, {
          ...defaultOptions,
          ...options,
          variant: "info",
        }),
    }),
    [ctx],
  );

  return toaster;
};
