import moment from "moment";
import { forwardRef, useCallback, useMemo } from "react";
import { SelectStore, useSelectStore } from "swash/Select";
import {
  DayModifiers,
  RangeDatePicker,
  SingleDatePicker,
} from "swash/controls/DatePicker";
import {
  RichSelectState,
  RichSelectStateProps,
  useRichSelectState,
} from "swash/v2/RichSelect";

export type Range = {
  from?: string | null;
  to?: string | null;
};

export type GeneratedPreset = {
  label: string;
  value: Range;
  id: number;
};

export const mTz = (value?: string | null) => moment.tz(value, "Europe/Paris");

export const formatDate = (date?: string | null) => {
  if (!date) {
    return null;
  }
  return mTz(date).format("YYYY-MM-DD");
};

export const parseDate = (date: string) => {
  return date ? mTz(date).toISOString() : null;
};

export const formatRange = (range: Range | null) => {
  if (!range) return null;
  return {
    from: formatDate(range.from),
    to: formatDate(range.to),
  };
};

export const parseRange = (range: Range | null) => {
  if (!range) return null;
  return {
    from: mTz(range.from).startOf("day").toISOString(),
    to: mTz(range.to ?? range.from)
      .endOf("day")
      .toISOString(),
  };
};

const formatDisplayDate = (value?: string | null) => {
  return mTz(value).format("D MMMM");
};

export const RangeDatePickerLabel = ({ value }: { value: Range }) => {
  if (!value.to || value.from === value.to) {
    return formatDisplayDate(value.from);
  }

  return `${mTz(value.from).format("D MMM")} → ${mTz(value.to).format(
    "D MMM",
  )}`;
};

type DatePickerProps = {
  defaultMonth?: Date;
  range?: boolean;
  presets?: GeneratedPreset[];
  modifiers?: DayModifiers;
};

export type DateSelectState = {
  datePicker: {
    presets: GeneratedPreset[];
  } & Omit<DatePickerProps, "presets">;
  select: SelectStore;
  onChange: (value: Range | null) => void;
  value: Range;
} & Omit<RichSelectState<Range>, "value">;

type DateSelectStateProps = DatePickerProps &
  RichSelectStateProps<Range, Range | null>;

export const useDateSelectState = (
  props: DateSelectStateProps,
): DateSelectState => {
  const {
    defaultMonth,
    range,
    presets = [],
    modifiers,
    onChange,
    valueSelector = (v) => JSON.stringify(v),
    labelSelector = (v) => <RangeDatePickerLabel value={v} />,
    ...rest
  } = props;
  const rich = useRichSelectState({
    valueSelector,
    labelSelector,
    onChange,
    ...rest,
  });

  const value = useMemo(() => {
    const { valueSelector } = rich;
    if (props.value === null) return "";
    return valueSelector(props.value);
  }, [props.value, rich]);

  const setValue = useCallback(
    (value: string | string[]) => {
      onChange(
        value === ""
          ? null
          : Array.isArray(value)
            ? value.map((v) => JSON.parse(v))
            : JSON.parse(value),
      );
    },
    [onChange],
  );

  const select = useSelectStore({
    value,
    setValue,
  });

  return {
    ...rich,
    value: rich.value as Range,
    select,
    onChange,
    datePicker: {
      modifiers: modifiers,
      defaultMonth: defaultMonth,
      range: range,
      presets: presets,
    },
  };
};

type SelectDatePickerProps = {
  state: DateSelectState;
  children?:
    | React.ReactNode
    | ((props: {
        value: Range | null;
        onChange: (value: Range) => void;
      }) => React.ReactNode);
  numberOfMonths?: number;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const SelectDatePicker = forwardRef<
  HTMLDivElement,
  SelectDatePickerProps
>(
  (
    {
      state: { value, onChange, datePicker },
      children,
      numberOfMonths = 2,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Picker = datePicker.range ? RangeDatePicker : SingleDatePicker;

    const handleChange = useCallback(
      (newValue: Range) => {
        onChange(
          !newValue && value && datePicker.range
            ? {
                from: value.from ?? value.to,
                to: value.from ?? value.to,
              }
            : newValue,
        );
      },
      [onChange, datePicker.range, value],
    );

    return (
      <div ref={ref} {...props}>
        {children && typeof children === "function" ? (
          children({ value, onChange })
        ) : (
          <Picker
            value={value as any}
            onChange={handleChange as any}
            numberOfMonths={numberOfMonths}
            disabled={disabled}
            {...datePicker}
          />
        )}
      </div>
    );
  },
);
