import { DialogDisclosureProps } from "@ariakit/react";
import React, { FC, forwardRef, useMemo, useRef } from "react";
import { Button } from "swash/Button";
import { Card, CardProps } from "swash/Card";
import { EmptyState, EmptyStateText, EmptyStateTitle } from "swash/EmptyState";
import { IoEyeOff, IoPencil } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { Truncate, type TruncateProps } from "swash/Truncate";
import { HTMLProps, Options } from "swash/types";
import { cn, cva } from "swash/utils/classNames";
import { useHasOverflow } from "swash/utils/useHasOverflow";

import { Image } from "@/components/Image";

const cardVariants = cva("group relative flex h-full cursor-grab", {
  variants: {
    direction: {
      horizontal: "flex-row items-center gap-2 p-2 border-none shadow-none",
      vertical: "flex-col overflow-hidden",
    },
  },
  defaultVariants: {
    direction: "vertical",
  },
});

type Scale = "sm" | "md" | "lg";

type LayoutMode = "list" | "grid";

type CardListItemProps = CardProps & {
  layoutMode?: LayoutMode;
  variant?: "primary" | "secondary" | "danger";
  direction?: "vertical" | "horizontal";
  scale?: Scale;
};

type CardListItemContextValue = Omit<CardListItemProps, "children">;

const CardListItemContext = React.createContext<CardListItemContextValue>({});

const useCardListItemContext = () => {
  return React.useContext(CardListItemContext);
};

const layoutModeVariants: Record<
  LayoutMode,
  Partial<CardListItemContextValue>
> = {
  list: {
    direction: "horizontal",
    scale: "sm",
    variant: "secondary",
  },
  grid: {
    direction: "vertical",
    scale: "md",
  },
};

const resolveContextValue = (
  values: Partial<CardListItemContextValue>,
  layoutMode?: LayoutMode,
) => {
  if (!layoutMode) {
    return values;
  }
  if (!values || Object.keys(values).length === 0) {
    return layoutModeVariants[layoutMode];
  }
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, layoutModeVariants[layoutMode]);
};

export const CardListItem = forwardRef<HTMLDivElement, CardListItemProps>(
  (
    { layoutMode, direction, variant, scale, className, children, ...props },
    ref,
  ) => {
    const value = useMemo(
      () =>
        resolveContextValue(
          {
            direction: direction || undefined,
            variant: variant || undefined,
            scale: direction
              ? scale || (direction === "horizontal" ? "sm" : "md")
              : scale,
          },
          layoutMode,
        ),
      [direction, variant, scale, layoutMode],
    );
    return (
      <CardListItemContext.Provider value={value}>
        <Card
          ref={ref}
          variant={value.variant}
          className={cn(
            cardVariants({ direction: value.direction }),
            className,
          )}
          role="listitem"
          data-test-no-radius
          {...props}
        >
          {children}
        </Card>
      </CardListItemContext.Provider>
    );
  },
);

const editDisclosureVariants = cva(
  "absolute z-30 opacity-0 group-hover:opacity-100",
  {
    variants: {
      direction: {
        horizontal:
          "-top-2.5 right-0 rounded-full bg-white p-1.5 ring-1 ring-inset ring-grey-border",
        vertical: "top-2 right-2 p-2",
      },
    },
    defaultVariants: {
      direction: "vertical",
    },
  },
);

type CardListItemEditDisclosureProps = {
  as: FC<Omit<DialogDisclosureProps, "state"> & { dialogProps: any }>;
  title?: string;
  className?: string;
  direction?: "horizontal" | "vertical";
};

export const CardListItemEditDisclosure: FC<
  CardListItemEditDisclosureProps
> = ({ as: As, title, direction, className, ...props }) => {
  const ctx = useCardListItemContext();
  return (
    <As
      dialogProps={props}
      render={
        <Button
          iconOnly
          scale="sm"
          appearance="fill-light"
          variant="secondary"
          className={cn(
            editDisclosureVariants({ direction: direction || ctx.direction }),
            className,
          )}
          title={title}
        />
      }
    >
      <IoPencil />
    </As>
  );
};

const thumbnailVariants = cva(
  "background-grid flex flex-col items-center justify-center overflow-hidden",
  {
    variants: {
      scale: {
        sm: "h-[52px] w-[72px]",
        fluid: "h-40 flex-none",
      },
    },
    defaultVariants: {
      scale: "fluid",
    },
  },
);

interface ThumbnailContainerProps {
  scale?: Scale | "fluid";
  className?: string;
  children: React.ReactNode;
}

const ThumbnailContainer = forwardRef<HTMLDivElement, ThumbnailContainerProps>(
  ({ scale, className, children }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          thumbnailVariants({
            scale: scale === "md" || scale === "lg" ? "fluid" : scale,
          }),
          className,
        )}
      >
        {children}
      </div>
    );
  },
);

type CardListItemThumbnailProps = Omit<ThumbnailContainerProps, "children"> &
  HTMLProps<Options<"div">> &
  HTMLProps<Options<"img">>;

export const CardListItemThumbnail: FC<CardListItemThumbnailProps> = ({
  scale,
  src,
  className,
  ...props
}) => {
  const ctx = useCardListItemContext();
  const resolvedScale = scale || ctx.scale;
  if (!src) {
    switch (resolvedScale) {
      case "sm":
        return (
          <Tooltip tooltip="Pas d’aperçu disponible">
            <ThumbnailContainer {...props} scale={resolvedScale}>
              <EmptyState className="text-white [&]:p-2">
                <EmptyStateTitle>
                  <IoEyeOff />
                </EmptyStateTitle>
              </EmptyState>
            </ThumbnailContainer>
          </Tooltip>
        );
      case undefined:
      case "fluid":
      case "md":
        return (
          <ThumbnailContainer {...props} scale={resolvedScale}>
            <EmptyState className="w-full text-white [&]:p-4">
              <EmptyStateTitle>
                <IoEyeOff />
              </EmptyStateTitle>
              <EmptyStateText className="text-xs font-semibold text-white">
                Pas d’aperçu disponible
              </EmptyStateText>
            </EmptyState>
          </ThumbnailContainer>
        );
      default:
        return null;
    }
  }
  return (
    <ThumbnailContainer className={className} scale={resolvedScale}>
      <div className="absolute inset-0 bg-dusk-bg-stronger opacity-0 transition group-hover:opacity-20" />
      <Image
        className="pointer-events-none z-10 shrink-0 overflow-hidden object-contain"
        src={src}
        {...props}
      />
    </ThumbnailContainer>
  );
};

const bodyVariants = cva("", {
  variants: {
    intent: {
      danger: "bg-danger-bg-light",
      primary: "",
      secondary: "",
    },
    direction: {
      horizontal: "flex-1 flex flex-col justify-center",
      vertical: "flex shrink-0 grow flex-col justify-between gap-1 p-2",
    },
    scale: {
      lg: "h-40",
      md: "h-20",
      sm: "h-[52px]",
    },
  },
  defaultVariants: {
    direction: "vertical",
  },
});

type CardListItemBodyProps = HTMLProps<Options<"div">> & {
  variant?: "danger";
  direction?: "horizontal" | "vertical";
  scale?: Scale | "lg";
};

export const CardListItemBody: FC<CardListItemBodyProps> = ({
  variant,
  direction,
  scale,
  className,
  children,
  ...props
}) => {
  const ctx = useCardListItemContext();

  // merge with parent context
  const value = useMemo(
    () => ({
      ...ctx,
      // override if provided
      ...(variant && { variant }),
      ...(direction && { direction }),
      ...(scale && { scale }),
    }),
    [ctx, direction, scale, variant],
  );
  return (
    <CardListItemContext.Provider value={value}>
      <div
        className={cn(
          bodyVariants({
            direction: value.direction,
            intent: value.variant,
            scale: value.direction === "horizontal" ? "sm" : value.scale,
          }),
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </CardListItemContext.Provider>
  );
};

const titleVariants = cva("text-sm", {
  variants: {
    scale: {
      lg: "font-semibold leading-5",
      md: "font-semibold leading-5",
      sm: "font-sans",
    },
  },
  defaultVariants: {
    scale: "md",
  },
});

type CardItemTitleProps = HTMLProps<Options<"div">> &
  TruncateProps & {
    scale?: Scale;
    maxLineClamp?: number;
  };

export const CardItemTitle: FC<CardItemTitleProps> = ({
  scale,
  className,
  children,
  maxLineClamp,
  ...props
}) => {
  const ref = useRef<HTMLElement | null>(null);
  const ctx = useCardListItemContext();
  const hasOverflow = useHasOverflow({ ref });
  const resolvedScale = scale || ctx.scale;
  const resolvedMaxLineClamp = (maxLineClamp ?? resolvedScale === "lg") ? 4 : 2;

  return (
    <Tooltip ref={ref} tooltip={hasOverflow ? children : null}>
      <Truncate
        className={cn(titleVariants({ scale: scale || ctx.scale }), className)}
        maxLineClamp={resolvedMaxLineClamp}
        {...props}
      >
        {children}
      </Truncate>
    </Tooltip>
  );
};
