import { DocumentNode, gql } from "@apollo/client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { TabState, useTabState } from "swash/Tab";
import { useToaster } from "swash/Toast";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useStoreState } from "swash/utils/useStoreState";

import { useSearchParams } from "@/components/SearchParams";
import { useSafeQuery } from "@/containers/Apollo";
import { ArticleCommentsArticleQuery } from "@/containers/article/panels/comments/ArticleComments";
import { ArticleNotesArticleQuery } from "@/containers/article/panels/comments/ArticleNotes";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";

type ArticleCommentsQueriesOptionsContextType = Record<
  "text" | "notes",
  {
    query: DocumentNode;
    variables: {
      articleId: number;
      resolved: boolean;
      offset?: number;
    };
  }
>;

const ArticleCommentsQueriesOptionsContext = createContext<
  ArticleCommentsQueriesOptionsContextType | undefined
>(undefined);

type ArticleCollaborativePanelTabStateContextType = TabState;

const ArticleCollaborativePanelTabStateContext = createContext<
  ArticleCollaborativePanelTabStateContextType | undefined
>(undefined);

export const useArticleCollaborativePanelTabState = () => {
  const context = useContext(ArticleCollaborativePanelTabStateContext);
  if (!context) {
    throw new Error(
      "useArticleCollaborativePanelTabState must be used within a ArticleCommentsProvider",
    );
  }
  return context;
};

type ArticleCommentsContextType = {
  selectedCommentsState: {
    panelScope: "comments" | "notes";
    selectedCommentIds: number[];
  };
  setSelectedCommentsState: (
    commentIds: number[],
    commentScope?: "text" | "notes" | "comments",
    /** @default false */
    preventPanelScroll?: boolean,
  ) => void;
  isCommentSelected: (commentId: number | number[]) => boolean;
  /** @default false */
  preventPanelScroll: boolean;
};

const ArticleCommentsContext = createContext<
  ArticleCommentsContextType | undefined
>(undefined);

type ArticleCommentPanelContextType = [
  "opened" | "resolved",
  (tab: "opened" | "resolved") => void,
];

const ArticleCommentPanelContext = createContext<
  ArticleCommentPanelContextType | undefined
>(undefined);

export const useArticleCommentQueriesOptions = () => {
  const context = useContext(ArticleCommentsQueriesOptionsContext);
  if (!context) {
    throw new Error(
      "useArticleCommentQueriesOptions must be used within a ArticleCommentsProvider",
    );
  }
  return context;
};

export const useArticleCommentsQueryOptions = () => {
  const scope = useCommentScope();
  const context = useContext(ArticleCommentsQueriesOptionsContext);
  if (!context) {
    throw new Error(
      "useArticleCommentsQueryOptions must be used within a ArticleCommentsProvider",
    );
  }
  return context[scope];
};

export const useArticleComments = () => {
  const context = useContext(ArticleCommentsContext);
  if (!context) {
    throw new Error(
      "useArticleComments must be used within a ArticleCommentsProvider",
    );
  }
  return context;
};

export const useArticleCommentPanel = () => {
  const context = useContext(ArticleCommentPanelContext);
  if (!context) {
    throw new Error(
      "useArticleCommentPanel must be used within a ArticleCommentsProvider",
    );
  }
  return context;
};

type FetchArticleCommentsAndNotesData = {
  article: {
    id: number;
    comments: {
      nodes: Array<{
        id: number;
        resolved: boolean;
        deleted: boolean;
      }>;
    };
    notes: {
      nodes: Array<{
        id: number;
        resolved: boolean;
        deleted: boolean;
      }>;
    };
  };
};

type FetchArticleCommentsAndNotesDataVariables = {
  articleId: number;
  commentIds: number[];
  includeComments: boolean;
  includeNotes: boolean;
};

// We just want to know if comments exist or if they have been deleted
// We also pick `resolved` to select the right status tab
const FetchArticleCommentsAndNotes = gql`
  query FetchArticleCommentsAndNotes(
    $articleId: Int!
    $commentIds: [Int!]!
    $includeComments: Boolean!
    $includeNotes: Boolean!
  ) {
    article(id: $articleId) {
      id
      comments(where: { id: { in: $commentIds } })
        @include(if: $includeComments) {
        nodes {
          id
          resolved
          deleted
        }
      }
      notes(where: { id: { in: $commentIds } }) @include(if: $includeNotes) {
        nodes {
          id
          resolved
          deleted
        }
      }
    }
  }
`;

type ArticleCommentsProviderProps = {
  children: React.ReactNode;
  articleId: number;
};

type PanelTab = "comments" | "notes";

export const ArticleCommentsProvider: React.FC<
  ArticleCommentsProviderProps
> = ({ children, articleId }) => {
  const toast = useToaster();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsCommentIds = searchParams.commentIds || [];
  const match = matchPath(
    { path: "articles/:articleId/collaborative/:tabId" },
    location.pathname,
  );

  const [preventPanelScroll, setPreventPanelScroll] = useState(false);

  const tab = useTabState({
    variant: "bar",
    defaultSelectedId: "notes",
    setSelectedId: (nextTabId) => {
      setSelectedCommentStatusTab("opened");
      setSelectedCommentsState({
        panelScope: nextTabId as PanelTab,
        selectedCommentIds: [],
      });
      navigate(`/articles/${articleId}/collaborative/${nextTabId}`);
    },
    selectedId: match?.params.tabId,
  });
  const tabId = useStoreState(tab.store, "selectedId") as PanelTab;

  const [selectedCommentsState, setSelectedCommentsState] = useState({
    panelScope: tabId,
    selectedCommentIds: paramsCommentIds,
  });

  const { selectedCommentIds, panelScope } = selectedCommentsState;

  const hasSelectedComment = selectedCommentIds.length > 0;

  const [selectedCommentStatusTab, setSelectedCommentStatusTab] = useState<
    "opened" | "resolved"
  >("opened");

  // comments lists use fetchMore to load more comments
  // we need to check if the queried comment is in the list
  const { data } = useSafeQuery<
    FetchArticleCommentsAndNotesData,
    FetchArticleCommentsAndNotesDataVariables
  >(FetchArticleCommentsAndNotes, {
    variables: {
      articleId,
      commentIds: selectedCommentIds,
      includeComments: panelScope === "comments",
      includeNotes: panelScope === "notes",
    },
    skip:
      !articleId ||
      !hasSelectedComment ||
      !match ||
      !["notes", "comments"].includes(tabId) ||
      // only fetch comments if we have comment ids query search params
      // otherwise selected comment must exist
      paramsCommentIds.length === 0,
  });

  const comments = data?.article[tabId].nodes;

  const isCommentSelected = useCallback(
    (commentId: number | number[]) => {
      if (Array.isArray(commentId)) {
        return commentId.every((id) => selectedCommentIds.includes(id));
      } else {
        return selectedCommentIds.includes(commentId);
      }
    },
    [selectedCommentIds],
  );

  const refs = useLiveRef({ panelScope, tabId });
  // if we have selected comments, and we are not on the right tab
  useEffect(() => {
    const { panelScope, tabId } = refs.current;
    if (hasSelectedComment && tabId !== panelScope) {
      navigate(`collaborative/${panelScope}`);
    }
  }, [hasSelectedComment, navigate, refs]);

  useEffect(() => {
    if (!comments || !comments.length) {
      return;
    }
    if (comments.every((comment) => comment.deleted)) {
      toast.warning(
        "Le ou les commentaires que vous cherchez à consulter ont été supprimés.",
      );
      setSearchParams({});
      return;
    }
    if (comments.every((comment) => comment.resolved)) {
      setSelectedCommentStatusTab("resolved");
    }
    setSearchParams({});
  }, [comments, setSearchParams, toast]);

  const handleSetSelectedState: ArticleCommentsContextType["setSelectedCommentsState"] =
    useCallback(
      (commentIds, commentScope, preventPanelScroll = false) => {
        const { panelScope } = refs.current;
        setPreventPanelScroll(preventPanelScroll);
        if (commentIds.length) {
          // only opened comments can be selected
          setSelectedCommentStatusTab("opened");
        }
        const target = (() => {
          switch (commentScope) {
            case "text":
              return "comments";
            case "notes":
              return "notes";
            default:
              return panelScope;
          }
        })();
        setSelectedCommentsState({
          panelScope: target,
          selectedCommentIds: commentIds,
        });
      },
      [refs],
    );

  const queriesOptions = useMemo(() => {
    const variables = {
      articleId,
      resolved: selectedCommentStatusTab === "resolved",
    };
    return {
      text: {
        query: ArticleCommentsArticleQuery,
        variables,
      },
      notes: {
        query: ArticleNotesArticleQuery,
        variables,
      },
    };
  }, [articleId, selectedCommentStatusTab]);

  return (
    <ArticleCommentsQueriesOptionsContext.Provider value={queriesOptions}>
      <ArticleCollaborativePanelTabStateContext.Provider value={tab}>
        <ArticleCommentsContext.Provider
          value={{
            setSelectedCommentsState: handleSetSelectedState,
            isCommentSelected,
            selectedCommentsState,
            preventPanelScroll,
          }}
        >
          <ArticleCommentPanelContext.Provider
            value={[selectedCommentStatusTab, setSelectedCommentStatusTab]}
          >
            {children}
          </ArticleCommentPanelContext.Provider>
        </ArticleCommentsContext.Provider>
      </ArticleCollaborativePanelTabStateContext.Provider>
    </ArticleCommentsQueriesOptionsContext.Provider>
  );
};
