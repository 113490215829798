import { useCallback, useRef } from "react";
import { type DragSourceHookSpec, useDrag } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useEventCallback } from "swash/utils/useEventCallback";

/**
 * Drag a native URL.
 */
export function useDragUrl(
  url: string | (() => string),
  options?: Omit<
    DragSourceHookSpec<unknown, unknown, unknown>,
    "type" | "item"
  >,
) {
  const getUrl = useEventCallback(() => {
    if (typeof url === "function") {
      return url();
    }
    return url;
  });
  const [collected, connectDrag, dragPreview] = useDrag({
    type: NativeTypes.URL,
    item: () => ({
      urls: [getUrl()],
    }),
    ...options,
  });
  const connectUniversalDrag = useCallback(
    (element: HTMLElement | null) => {
      if (!element) return undefined;
      const listener = (event: DragEvent) => {
        const url = getUrl();
        event.dataTransfer?.setData("url", url);
        event.dataTransfer?.setData("text", url);
      };
      element.addEventListener("dragstart", listener);
      return () => {
        element.removeEventListener("dragstart", listener);
      };
    },
    [getUrl],
  );
  const disconnectUniversalDragRef = useRef<() => void>();
  const urlDrag = useCallback(
    (element: HTMLElement | null) => {
      connectDrag(element);
      disconnectUniversalDragRef.current?.();
      disconnectUniversalDragRef.current = connectUniversalDrag(element);
    },
    [connectDrag, connectUniversalDrag],
  );
  return [collected, urlDrag, dragPreview] as const;
}
