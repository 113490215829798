import { UseFieldConfig, useField } from "react-final-form";
import { Indice0, Indice1, Indice2, Indice3 } from "swash/Icon";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

type EditorialLevel = {
  label: string;
  value: number;
};

export const editorialLevels: EditorialLevel[] = [
  {
    label: "Indice 0",
    value: 0,
  },
  {
    label: "Indice 1",
    value: 1,
  },
  {
    label: "Indice 2",
    value: 2,
  },
  {
    label: "Indice 3",
    value: 3,
  },
];

type EditorialLevelIconProps = {
  editorialLevel: EditorialLevel;
};
function EditorialLevelIcon({ editorialLevel }: EditorialLevelIconProps) {
  switch (editorialLevel.value) {
    case 3:
      return (
        <Indice3
          aria-label={editorialLevel.label}
          className="text-xl text-blue-800"
        />
      );
    case 2:
      return (
        <Indice2
          aria-label={editorialLevel.label}
          className="text-xl text-blue-500"
        />
      );
    case 1:
      return (
        <Indice1
          aria-label={editorialLevel.label}
          className="text-xl text-blue-300"
        />
      );
    case 0:
      return (
        <Indice0
          aria-label={editorialLevel.label}
          className="text-xl text-gray-300"
        />
      );
    default:
      return null;
  }
}

type EditorialLevelFieldProps = {
  name?: string;
  disabled?: boolean;
  multi?: boolean;
  clearable?: boolean;
} & UseFieldConfig<any>;
export const EditorialLevelField = ({
  name = "editorialLevel",
  disabled = false,
  multi = true,
  clearable = true,
  ...others
}: EditorialLevelFieldProps) => {
  const field = useField(name, {
    ...others,
  });

  const emptyValue = multi ? [] : null;

  const select = useEnumSelectState({
    title: "Indice",
    value: field.input.value || emptyValue,
    onChange: field.input.onChange,
    required: !clearable,
    items: editorialLevels,
    labelSelector: (editorialLevel) => (
      <EditorialLevelIcon editorialLevel={editorialLevel} />
    ),
    valueSelector: (editorialLevel) => String(editorialLevel.value),
  });

  return (
    <div>
      <EnumSelect
        state={select}
        disabled={disabled}
        placeholder="Indice"
        aria-label="Indice"
      />
    </div>
  );
};
