import { useStoreState } from "@ariakit/react";
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";

import { Popover, PopoverDisclosure, usePopoverStore } from "../Popover";
import { MaskInput } from "../internal/MaskInput";
import { TextboxClearAdornment } from "../internal/TextboxClearAdornment";
import { useId } from "../utils/hooks";
import { useLiveRef } from "../utils/useLiveRef";
import { SingleDatePicker } from "./DatePicker";
import { Textbox } from "./Textbox";

const format = (value: string | null) => {
  if (!value) return value;
  const [YYYY, MM, DD] = value.split("-");
  return [DD, MM, YYYY].filter(Boolean).join("/");
};

const parse = (value: string | null) => {
  if (!value) return value;
  const [DD, MM, YYYY] = value.split("/");
  return [YYYY, MM, DD].filter(Boolean).join("-");
};

const DATE_REGEXP = /\d{4}-\d{2}-\d{2}/;

export const checkIsValidDateValue = (value: string | null) =>
  value?.length === 10 &&
  DATE_REGEXP.test(value) &&
  !Number.isNaN(new Date(value).getTime());

const getPopoverAriaProps = (props: {
  "aria-label"?: string;
  "aria-labelledby"?: string;
}) => {
  if (props["aria-label"]) return { "aria-label": props["aria-label"] };
  if (props["aria-labelledby"])
    return { "aria-labelledby": props["aria-labelledby"] };
  return {};
};

type SingleDatePickerInputProps = {
  id?: string;
  value: string | null;
  onChange: Dispatch<SetStateAction<string | null>>;
  scale?: "sm" | "md" | "lg";
  header?: React.ReactNode;
  modifiers?: Record<string, (date: Date) => boolean>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  modal?: boolean;
  children?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  "aria-label"?: string;
  "aria-labelledby"?: string;
} & React.ComponentProps<typeof Textbox>;

export const SingleDatePickerInput = forwardRef<
  HTMLButtonElement,
  SingleDatePickerInputProps
>((props, ref) => {
  const {
    scale = "lg",
    value: propValue,
    onChange,
    children,
    header,
    modifiers,
    onKeyDown,
    onBlur,
    modal,
    ...otherProps
  } = props;
  const id = useId(otherProps.id ?? undefined);
  const [internalValue, setInternalValue] = useState(propValue);
  const value = format(propValue);
  const refs = useLiveRef({ internalValue, value, onChange });
  // When internalValue changes, trigger onChange if necessary
  useEffect(() => {
    const { value, onChange } = refs.current;
    if (internalValue !== value) {
      const parsedValue = parse(internalValue);
      if (checkIsValidDateValue(parsedValue)) {
        onChange(parsedValue);
      }
    }
  }, [internalValue, refs]);
  // When value changes, update internalValue if necessary
  useEffect(() => {
    const { internalValue } = refs.current;
    if (internalValue !== value) {
      setInternalValue(value);
    }
  }, [value, refs]);
  const popoverRef = useRef(null);
  const textBoxRef = useRef<HTMLButtonElement>(null);
  const textBoxRefs = useMemo(
    () => mergeRefs([ref, textBoxRef]),
    [ref, textBoxRef],
  );
  const popover = usePopoverStore({
    placement: "bottom-start",
  });
  const open = useStoreState(popover, "open");
  // Keep focusing while visible
  useEffect(() => {
    if (open && textBoxRef.current) {
      textBoxRef.current.focus();
    }
  });
  const validPropValueRef = useRef<string | null>(null);
  if (checkIsValidDateValue(propValue)) {
    validPropValueRef.current = propValue;
  }
  return (
    <>
      <PopoverDisclosure
        ref={textBoxRefs}
        id={id}
        store={popover}
        render={({ role, onKeyDown: ignored, ...disclosureProps }) => (
          <Textbox
            as={MaskInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInternalValue(event.currentTarget.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (onKeyDown) onKeyDown(event);
              if (event.defaultPrevented) return;
              switch (event.key) {
                case "Escape": {
                  event.preventDefault();
                  event.stopPropagation();
                  popover.hide();
                  return;
                }
                case " ": {
                  event.preventDefault();
                  event.stopPropagation();
                  popover.show();
                  return;
                }
              }
            }}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            placeholder="JJ/MM/AAAA"
            scale={scale}
            value={internalValue ?? ""}
            role="combobox"
            minWidth={128}
            autoComplete="off"
            {...disclosureProps}
          >
            {!otherProps.required && value ? (
              <TextboxClearAdornment onClick={() => onChange(null)} />
            ) : null}
          </Textbox>
        )}
        {...otherProps}
      />
      <Popover
        ref={popoverRef}
        store={popover}
        modal={modal}
        {...getPopoverAriaProps(otherProps)}
      >
        {open && (
          <>
            {header}
            <SingleDatePicker
              className="p-4"
              value={validPropValueRef.current}
              onChange={onChange}
              disabled={otherProps.disabled}
              modifiers={modifiers}
            />
          </>
        )}
      </Popover>
    </>
  );
});

if (process.env["NODE_ENV"] !== "production") {
  SingleDatePickerInput.displayName = "SingleDatePickerInput";
}
