import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { Link } from "swash/Link";

import { Time } from "@/components/Time";

import { EventStatusIndicator } from "./EventStatusIndicator";

const EVENTS = {
  articleBranch: {
    prePublish: "Export print",
    publish: "Exporté print",
  },
};

const EventDetail = ({ event }) => {
  return (
    <EventStatusIndicator event={event}>
      <div>
        {EVENTS[event.name]?.[event.action] ?? "Événement"}
        <span className="text-grey-on">
          {" | "}
          <Time date={event.date}>
            {moment.tz(event.date, "Europe/Paris").format("DD/MM/YYYY à HH:mm")}
          </Time>
          {event.actor && ` | ${event.actor.fullName}`}
        </span>
      </div>
    </EventStatusIndicator>
  );
};

export function RichEventsHistory({ events, onLoadMore, loading, ...props }) {
  return (
    <div className="mt-2 flex flex-col gap-3 text-xs" {...props}>
      {events.nodes.map((event) => (
        <EventDetail key={event.id} event={event} />
      ))}
      {events.pageInfo.hasMore && (
        <div className="mt-1">
          <Link asChild>
            <button
              type="button"
              disabled={loading}
              onClick={(event) => {
                event.stopPropagation();
                onLoadMore();
              }}
            >
              Voir plus...
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

const EventFragment = gql`
  fragment RichEventsHistory_event on Event {
    id
    name
    action
    date
    actor {
      id
      fullName
    }
    statuses {
      id
    }
    ...EventStatusIndicator_event
  }

  ${EventStatusIndicator.fragments.event}
`;

RichEventsHistory.fragments = {
  event: EventFragment,
  events: gql`
    fragment RichEventsHistory_events on EventConnection {
      pageInfo {
        hasMore
        limit
      }
      nodes {
        id
        ...RichEventsHistory_event
      }
    }

    ${EventStatusIndicator.fragments.event}
    ${EventFragment}
  `,
};
