/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from "moment-timezone";
import { Fragment } from "react";
import { cn } from "swash/utils/classNames";

import { Clock } from "@/components/icons";
import { ArticleNode } from "@/containers/editor/nodes/ArticleNode";
import { CustomTypeContentNode } from "@/containers/editor/nodes/CustomTypeContentNode";
import { ImageNode } from "@/containers/editor/nodes/ImageNode";
import { SnippetNode } from "@/containers/editor/nodes/SnippetNode";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";

import { LivePostStatus } from "./LivePostStatus";
import { ContributionNodeDisplay } from "./postNodes/ContributionNode";

const replaceEmojis = (text, emojis = []) => {
  if (emojis.length === 0) return text;
  return text.replace(/:([a-z0-9+_-]+):/g, (string, alt) => {
    const matchingEmoji = emojis.find((emoji) => emoji.name === alt);
    if (!matchingEmoji) return alt;
    return `<img alt=':${alt}:' src="${matchingEmoji.displayImage}" className="h-3.5" />`;
  });
};

const PostTitle = ({ children }) => (
  <h3 className="text-lg font-semibold">{children}</h3>
);

const TextNode = (props) => (
  <p className="live-node-hyper-link m-0" {...props} />
);

const HeaderTwoNode = ({ children, ...props }) => (
  <h2 className="live-node-hyper-link m-0 text-lg font-semibold" {...props}>
    {children}
  </h2>
);

const HeaderThreeNode = ({ children, ...props }) => (
  <h3 className="live-node-hyper-link m-0 text-base font-semibold" {...props}>
    {children}
  </h3>
);

const UnorderedListItemNode = (props) => (
  <p
    className="live-node-hyper-link m-0 before:mr-2 before:content-['•']"
    {...props}
  />
);

const OrderedListItemNode = ({ count, ...props }) => (
  <p
    className="live-node-hyper-link m-0 before:mr-2"
    style={{
      ":before": {
        content: `${props.count}-`,
      },
    }}
    {...props}
  />
);

const BlockquoteNode = (props) => (
  <blockquote
    className="[&>a]:live-node-hyper-link m-0 border-l-2 border-l-dusk-border-strong pl-2 italic"
    {...props}
  />
);

function FormatSignature({ signature, ...props }) {
  const { author, origin } = signature;
  return (
    <Fragment {...props}>
      <span className="font-semibold">{author.longName}</span>
      {origin && ` (${origin})`}
    </Fragment>
  );
}

const handleClick = (event) => {
  // Open all links in new tab
  if (event.target.nodeName.toUpperCase() === "A" && event.target.href) {
    event.target.target = "_blank";
  }
};

export function LivePostContent({ post, lock, actions, header }) {
  const emojis = [];

  const scheduledAt =
    post.scheduledAt && !post.published
      ? moment.tz(post.scheduledAt, "Europe/Paris").format("HH:mm - DD/MM/YY")
      : null;
  let count = 0;

  return (
    <div className="flex flex-col gap-2 p-4" onClick={handleClick}>
      {header}
      {post.title ? <PostTitle>{post.title}</PostTitle> : null}
      {post.nodes.map((node) => {
        if (node.metadata?.type !== "ordered_list_item") count = 0;
        switch (node.type) {
          case "text":
            switch (node.metadata.type) {
              case "blockquote":
                return (
                  <div key={node.id}>
                    <BlockquoteNode
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
              case "unordered_list_item":
                return (
                  <div key={node.id}>
                    <UnorderedListItemNode
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
              case "ordered_list_item":
                count++;
                return (
                  <div key={node.id}>
                    <OrderedListItemNode
                      count={count}
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
              case "header_two":
                return (
                  <div key={node.id}>
                    <HeaderTwoNode
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
              case "header_three":
                return (
                  <div key={node.id}>
                    <HeaderThreeNode
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
              default:
                return (
                  <div key={node.id}>
                    <TextNode
                      dangerouslySetInnerHTML={{
                        __html: replaceEmojis(node.text, emojis),
                      }}
                    />
                  </div>
                );
            }
          case "article":
            return (
              <div key={node.id}>
                <ArticleNode
                  article={node.article}
                  metadata={node.articleMetadata}
                />
              </div>
            );
          case "contribution":
            return (
              <div key={node.id}>
                <ContributionNodeDisplay contribution={node.contribution} />
              </div>
            );
          case "tweet":
            return (
              <div key={node.id}>
                <TweetNode tweet={node.tweet} />
              </div>
            );
          case "image":
            return (
              <div key={node.id}>
                <ImageNode image={node.image} metadata={node.imageMetadata} />
              </div>
            );
          case "video":
            return (
              <div key={node.id}>
                <VideoNode video={node.video} />
              </div>
            );
          case "snippet":
            return (
              <div key={node.id}>
                <SnippetNode snippet={node.snippet} />
              </div>
            );
          case "custom_types_content":
            return (
              <div key={node.id}>
                <CustomTypeContentNode content={node.customTypesContent} />
              </div>
            );
          default:
            return null;
        }
      })}

      {post.signatures.length > 0 && (
        <div className="my-4 text-sm text-grey-on">
          {post.signatures.reduce((res, signature, index) => {
            if (index === post.signatures.length - 1) {
              return [
                ...res.slice(0, -1),
                res.length > 0 ? (
                  <Fragment key={index * 2 - 1}> et </Fragment>
                ) : null,
                <FormatSignature key={index * 2} signature={signature} />,
              ];
            }
            return [
              ...res,
              <FormatSignature key={index * 2} signature={signature} />,
              <Fragment key={index * 2 + 1}>, </Fragment>,
            ];
          }, [])}
        </div>
      )}
      <div
        className={cn(
          "flex flex-row items-center gap-4",
          post.flag || scheduledAt ? "justify-between" : "justify-end",
        )}
      >
        {post.flag && (
          <div style={{ maxWidth: 144 }} className="truncate">
            <div className="inline font-accent text-sm font-semibold">
              {post.flag.label}
            </div>
          </div>
        )}
        {scheduledAt && (
          <div className="flex grid-flow-col gap-1 font-accent text-sm font-semibold">
            <Clock />
            {scheduledAt}
          </div>
        )}
        <div className="flex items-center gap-4">
          <LivePostStatus post={post} lock={lock} />
          {actions}
        </div>
      </div>
    </div>
  );
}
