import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<"textarea">
>(function Textarea(props, ref) {
  return (
    <textarea
      ref={ref}
      {...props}
      className={cn(
        "appearance-none rounded border bg-white p-2 font-sans text-base text-dusk-on transition-all",
        "placeholder:text-grey-on-strong",
        "focus:border-primary-border",
        "hover:[&:not(:disabled)]:border-primary-border",
        "active:[&:not(:disabled)]:shadow",
        "disabled:opacity-40",
        "read-only:opacity-75",
        "aria-[invalid]:border-danger-border-strong",
        "aria-[invalid]:focus:border-danger-border-strong",
        "aria-[invalid]:hover:border-danger-border-strong",
        props.className,
      )}
    />
  );
});
