import { gql } from "@apollo/client";
import moment from "moment";
import { forwardRef, useCallback, useRef, useState } from "react";
import { CiUndo } from "react-icons/ci";
import { Button } from "swash/Button";
import { Card } from "swash/Card";
import {
  EllipsisVertical,
  IoCheckmark,
  IoPencil,
  IoTrash,
  LinkTilted,
} from "swash/Icon";
import { Loader } from "swash/Loader";
import { Menu, MenuButton, MenuItem, useMenuStore } from "swash/Menu";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";
import { useStoreState } from "swash/utils/useStoreState";
import { useClipboard } from "use-clipboard-copy";

import { Time } from "@/components/Time";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useSafeMutation } from "@/containers/Apollo";
import { Comment as DisplayComment } from "@/containers/Comment";
import { useArticleComments } from "@/containers/article/ArticleCommentsContext";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";
import { ResolvedCommentBlockInfos } from "@/containers/article/panels/comments/ResolvedCommentBlockInfos";
import { useArticleId } from "@/containers/routes/article/ArticleContext";
import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";

import {
  CreateArticleCommentForm,
  UpdateArticleCommentForm,
} from "./ArticleCommentForm";

const ActionsButton = ({ menu }) => {
  const open = useStoreState(menu, "open");
  return (
    <Tooltip tooltip={open ? null : "Plus d’actions"} placement="top">
      <MenuButton asChild store={menu}>
        <Button
          appearance="text"
          variant="secondary"
          aria-label="Actions"
          scale="xs"
          iconOnly
        >
          <EllipsisVertical />
        </Button>
      </MenuButton>
    </Tooltip>
  );
};

const ActionsMenu = ({ menu, setReadOnly, setDeletingComment, comment }) => {
  const clipboard = useClipboard();
  const articleId = useArticleId();
  const scope = useCommentScope();

  const refs = useRef({ articleId, comment, clipboard, scope });

  const copyLink = useCallback(() => {
    const { articleId, comment, clipboard, scope } = refs.current;
    clipboard.copy(
      `${window.location.origin}/articles/${articleId}/collaborative/${
        scope === "notes" ? "notes" : "comments"
      }?commentIds[]=${comment.id}`,
    );
  }, []);

  return (
    <Menu store={menu} aria-label="Actions">
      <MenuItem
        scale="sm"
        onClick={() => {
          setReadOnly(false);
          menu.hide();
        }}
      >
        <IoPencil />
        Modifier
      </MenuItem>
      <MenuItem scale="sm" onClick={copyLink}>
        <LinkTilted />
        Copier le lien
      </MenuItem>
      <MenuItem
        scale="sm"
        variant="danger"
        onClick={() => {
          setDeletingComment(comment);
          menu.hide();
        }}
      >
        <IoTrash />
        Supprimer
      </MenuItem>
    </Menu>
  );
};

const DisplayAnchorText = ({ comment }) => {
  const scope = useCommentScope();
  // there is no anchor text for notes and responses
  if (scope === "notes" || comment.parentId) return null;
  const { anchorText } = comment;
  return (
    <span className="flex rounded-l-lg rounded-r-md bg-orange-bg pl-1 text-sm group-data-[resolved]:bg-grey-border">
      <p className="line-clamp-3 flex-1 rounded-md bg-orange-bg-light px-2 py-1 group-data-[focused]:bg-transparent group-data-[resolved]:bg-grey-bg">
        {anchorText ? anchorText : <Loader />}
      </p>
    </span>
  );
};

DisplayAnchorText.fragments = {
  comment: gql`
    fragment DisplayAnchorText_comment on Comment {
      id
      ... on CommentResponse {
        parentId
      }
      ... on CommentThread {
        anchorText
      }
    }
  `,
};

const ToggleArticleCommentMutation = gql`
  mutation ArticleComment_updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
    }
  }
`;

const ResolveCommentToggleAction = ({ comment }) => {
  const commentRef = useRef(comment);
  const scope = useCommentScope();
  const { setSelectedCommentsState } = useArticleComments();
  const [updateComment, { loading }] = useSafeMutation(
    ToggleArticleCommentMutation,
    {
      skip: comment.parentId,
      onCompleted: () => {
        setSelectedCommentsState([commentRef.current.id], scope);
      },
    },
  );
  const [resolved, setResolved] = useEnhancedState(
    comment.resolved,
    (nextState) => {
      updateComment({
        variables: {
          input: {
            id: comment.id,
            resolved: nextState,
          },
        },
        optimisticResponse: {
          updateComment: {
            __typename: "CommentThread",
            ...comment,
            resolved: nextState,
          },
        },
      });
      return nextState;
    },
  );
  if (comment.parentId || loading) return null;
  if (resolved) {
    return (
      <Tooltip tooltip="Restaurer" placement="top">
        <Button
          appearance="text"
          variant="secondary"
          aria-label="Restaurer"
          scale="xs"
          iconOnly
          onClick={() => {
            setResolved(false);
          }}
        >
          <CiUndo />
        </Button>
      </Tooltip>
    );
  }
  return (
    <Tooltip tooltip="Résoudre" placement="top">
      <Button
        appearance="text"
        variant="secondary"
        aria-label="Résoudre"
        scale="xs"
        iconOnly
        onClick={() => {
          setResolved(true);
        }}
      >
        <IoCheckmark />
      </Button>
    </Tooltip>
  );
};

ResolveCommentToggleAction.fragments = {
  comment: gql`
    fragment ResolveCommentToggleAction_comment on Comment {
      id
      ... on CommentResponse {
        parentId
      }
      ... on CommentThread {
        resolved
      }
    }
  `,
};

const Comment = ({ comment, setDeletingComment }) => {
  const { user, mine, date } = comment;
  const menu = useMenuStore();
  const [readOnly, setReadOnly] = useState(true);

  const userDenomination = `${user.firstNameInitials} ${user.lastName}`;
  return (
    <div className="group flex w-full flex-col gap-y-2">
      <div className="flex items-center gap-2 text-sm leading-6">
        <UserHoverCardTooltip user={user}>
          <div className="flex items-center gap-2">
            <UserAvatar user={user} size={18} />
            <span className="text-sm leading-6">
              <span className="font-semibold">{userDenomination}</span>
            </span>
          </div>
        </UserHoverCardTooltip>
        <span className="text-grey-on before:mr-2 before:content-['•']">
          <Time date={date}>{moment(date).format("DD/MM/YYYY à HH:mm")}</Time>
        </span>
        <div className="ml-auto opacity-0 transition-opacity group-hover:opacity-100 aria-expanded:opacity-100">
          <ResolveCommentToggleAction comment={comment} />

          {mine && (
            <>
              <ActionsButton menu={menu} />
              <ActionsMenu
                menu={menu}
                comment={comment}
                setDeletingComment={setDeletingComment}
                setReadOnly={setReadOnly}
              />
            </>
          )}
        </div>
      </div>
      <DisplayAnchorText comment={comment} />
      {readOnly ? (
        <DisplayComment comment={comment} />
      ) : (
        <UpdateArticleCommentForm
          comment={comment}
          onSubmit={() => {
            setReadOnly(true);
          }}
          onDiscard={() => setReadOnly(true)}
        />
      )}
    </div>
  );
};

Comment.fragments = {
  comment: gql`
    fragment ArticleComment_Comment_comment on Comment {
      id
      date
      mine
      user {
        id
        firstNameInitials
        lastName
        ...UserHoverCardTooltip_user
        ...UserAvatar_user
      }
      ...ResolveCommentToggleAction_comment
      ...DisplayAnchorText_comment
      ...Comment_comment
    }
    ${UserHoverCardTooltip.fragments.user}
    ${UserAvatar.fragments.user}
    ${ResolveCommentToggleAction.fragments.comment}
    ${DisplayAnchorText.fragments.comment}
    ${DisplayComment.fragments.comment}
  `,
};

const wordings = {
  notes: {
    label: "Réponses à la consigne",
    placeholder: "Répondre à la consigne...",
  },
  text: {
    label: "Réponses au commentaire",
    placeholder: "Répondre au commentaire...",
  },
};

const ArticleCommentResponseList = ({
  setDeletingComment,
  article,
  comment,
}) => {
  const scope = useCommentScope();
  const responses = comment.responses.nodes;
  const parentId = comment.id;

  const { label, placeholder } = wordings[scope] ?? wordings.text;

  return (
    <>
      {responses.length ? (
        <div role="list" aria-label={label} className="flex flex-col gap-4">
          {responses.map((response) => (
            <div
              key={response.id}
              role="listitem"
              className="flex w-full flex-col items-start px-4 last:pb-4"
            >
              <Comment
                comment={response}
                setDeletingComment={setDeletingComment}
              />
            </div>
          ))}
        </div>
      ) : null}
      {!comment.resolved && (
        <CreateArticleCommentForm
          article={article}
          parentId={parentId}
          confirmMessage="Répondre"
          placeholder={placeholder}
          showFormButtons={false}
          className={cn(!responses.length && "pt-4")}
        />
      )}
    </>
  );
};

ArticleCommentResponseList.fragments = {
  commentThread: gql`
    fragment ArticleCommentResponseList_commentThread on CommentThread {
      id
      resolved
      responses {
        nodes {
          id
          ...ArticleComment_Comment_comment
        }
      }
    }
    ${Comment.fragments.comment}
  `,
};

export const ArticleComment = forwardRef(
  ({ comment, setDeletingComment, article }, ref) => {
    const { setSelectedCommentsState, isCommentSelected } =
      useArticleComments();
    const scope = useCommentScope();
    const commentSelected = isCommentSelected(comment.id);
    const selectable = scope === "text" && !comment.resolved;
    const refs = useRef({
      selectable,
      setSelectedCommentsState,
      comment,
      scope,
    });

    const handleFocus = useCallback(() => {
      const { selectable, setSelectedCommentsState, comment, scope } =
        refs.current;
      if (!selectable) return;
      setSelectedCommentsState([comment.id], scope, true);
    }, []);

    const handleBlur = useCallback((event) => {
      const { setSelectedCommentsState } = refs.current;
      if (event.currentTarget.contains(event.relatedTarget)) {
        return;
      }
      setSelectedCommentsState([]);
    }, []);

    return (
      <div role="listitem" className="flex flex-col items-start gap-2">
        <ResolvedCommentBlockInfos comment={comment} />
        <Card
          ref={ref}
          tabIndex={0}
          data-focused={commentSelected ? "" : undefined}
          data-resolved={comment.resolved ? "" : undefined}
          className={cn(
            "group flex w-full flex-col overflow-hidden",
            selectable && "cursor-pointer",
          )}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <div
            className={cn(
              "group p-4",
              scope === "text" &&
                "group-data-[focused]:bg-orange-bg-light group-data-[resolved]:group-data-[focused]:bg-grey-bg-light",
            )}
          >
            <Comment
              comment={comment}
              setDeletingComment={setDeletingComment}
            />
          </div>
          <ArticleCommentResponseList
            setDeletingComment={setDeletingComment}
            article={article}
            comment={comment}
          />
        </Card>
      </div>
    );
  },
);

ArticleComment.fragments = {
  commentThread: gql`
    fragment ArticleComment_commentThread on CommentThread {
      id
      resolved
      scope
      ...ResolvedCommentBlockInfos_commentThread
      ...ArticleComment_Comment_comment
      ...ArticleCommentResponseList_commentThread
    }
    ${ResolvedCommentBlockInfos.fragments.commentThread}
    ${Comment.fragments.comment}
    ${ArticleCommentResponseList.fragments.commentThread}
  `,
};
