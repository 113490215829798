import { gql } from "@apollo/client";

import { useModernDraggableCapsuleLabelId } from "@/containers/ModernDraggableCapsule";
import { ArticleLink } from "@/containers/article/ArticleLink";
import { ArticleTitle } from "@/containers/article/ArticleTitle";
import type { CapsuleTitle_articleFragment } from "@/gql-types";

type CapsuleTitleProps = {
  article: CapsuleTitle_articleFragment;
  title?: string;
};
export const CapsuleTitle = ({ article, title }: CapsuleTitleProps) => {
  const id = useModernDraggableCapsuleLabelId();
  return (
    <h5 id={id ?? undefined} className="text-sm leading-[1.125rem]">
      <ArticleLink inherit articleId={article.id} target="_blank">
        {article.deleted ? "Article supprimé — " : null}
        {title || <ArticleTitle article={article} />}{" "}
      </ArticleLink>
    </h5>
  );
};

CapsuleTitle.fragments = {
  article: gql`
    fragment CapsuleTitle_article on Article {
      id
      deleted
      ...ArticleTitle_article
    }
    ${ArticleTitle.fragments.article}
  `,
};
