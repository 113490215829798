import {
  DialogPanel,
  DialogPanelDisclosure,
  useDialogPanelStore,
} from "swash/DialogPanel";
import {
  PanelBody,
  PanelHeader,
  PanelSection,
  PanelSectionArrow,
  PanelSectionHeader,
  PanelSectionTitle,
} from "swash/Panel";
import { useStoreState } from "swash/utils/useStoreState";

import { ActivityHistory } from "../admin/CRUD/history/ActivityHistory";

export const ActivitySection = ({ title, ...props }) => {
  const dialog = useDialogPanelStore();
  const activityTitle = title || "Activités";

  return (
    <>
      <DialogPanelDisclosure store={dialog} render={<PanelSection />}>
        <PanelSectionHeader>
          <PanelSectionTitle>{activityTitle}</PanelSectionTitle>
          <PanelSectionArrow />
        </PanelSectionHeader>
      </DialogPanelDisclosure>
      <ImageActivityDialog store={dialog} title={activityTitle} {...props} />
    </>
  );
};

const ImageActivityDialog = ({
  resource,
  resourceId,
  fieldsMap,
  store,
  title,
  noComment,
}) => {
  const open = useStoreState(store, "open");
  const animating = useStoreState(store, "animating");
  const visible = resourceId && (open || animating);
  if (!visible) return null;

  return (
    <DialogPanel
      className="absolute inset-y-0 right-0 z-dialog-panel"
      style={{ width: 424 }}
      fullScreen={false}
      store={store}
    >
      <PanelHeader title={title} back="Retour" onClose={store.hide} />
      <PanelBody>
        <ActivityHistory
          resource={resource}
          resourceId={resourceId}
          fieldsMap={fieldsMap}
          term={{
            article: "une",
            specificArticle: "l’",
            singular: "image",
            plural: "images",
          }}
          noComment={noComment}
        />
      </PanelBody>
    </DialogPanel>
  );
};
