import { gql, useApolloClient } from "@apollo/client";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "swash/Button";
import { IoCloseCircleOutline } from "swash/Icon";
import { useStoreState } from "swash/utils/useStoreState";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";
import { useDebounce } from "use-debounce";

import { FieldLabel } from "@/components/fields/FieldLabel";
import { useSelectField } from "@/components/fields/SelectField";
import { useSafeQuery } from "@/containers/Apollo";
import { ArticleTitle } from "@/containers/article/ArticleTitle";

import { ArticleLink } from "../ArticleLink";

const articlesQuery = gql`
  query ArticleFiltersQuery($search: String) {
    articles(where: { search: $search }) {
      nodes {
        id
        ...ArticleTitle_article
      }
    }
  }

  ${ArticleTitle.fragments.article}
`;

export const ArticleField = memo(({ name, label, placeholder, ...props }) => {
  const client = useApolloClient();
  const combobox = useComboboxStore();
  const search = useStoreState(combobox, "value");

  const field = useSelectField(name, {
    label,
    ...props,
  });
  const { value, onChange } = field.state.field.input;

  const dataRef = useRef();
  const [defaultValue] = useState(Boolean(value?.articleId));
  const [articleTitle, setArticleTitle] = useState(null);
  const [searchValue] = useDebounce(search, 400);
  const { data } = useSafeQuery(articlesQuery, {
    variables: { search: searchValue },
    skip: !searchValue || searchValue === "",
    onCompleted: ({ articles }) => {
      if (!defaultValue) return;
      const article = articles.nodes.find(
        (article) => String(article.id) === String(value.articleId),
      );
      if (!article) return;
      setArticleTitle(article.title);
    },
  });

  useEffect(() => {
    if (value?.articleId) {
      combobox.setValue(String(value.articleId));
    }
  });

  const nodes = useMemo(
    () => ({
      items: data?.articles.nodes ?? [],
      totalCount: data?.articles.nodes.length ?? 0,
      hasMore: false,
    }),
    [data?.articles.nodes],
  );

  dataRef.current = data;

  const select = useRemoteSelectState({
    title: label,
    value: value?.id ?? null,
    onChange: (article) => {
      if (!article) {
        onChange(null);
        return;
      }
      onChange({
        articleId: article.id,
      });
      setArticleTitle(article.title);
    },
    data: nodes,
    combobox,
    labelSelector: (article) => (
      <>
        <ArticleTitle article={article} /> ({article.id})
      </>
    ),
    valueSelector: (article) => article.id?.toString(),
    getItem: (id) => {
      const value = client.readFragment({
        id: `Article:${id}`,
        fragment: ArticleTitle.fragments.article,
      });
      if (!value) {
        throw new Error("No article found");
      }
      return value;
    },
  });

  return (
    <div className="flex flex-col gap-2">
      <FieldLabel {...field}>{label}</FieldLabel>
      {articleTitle ? (
        <ArticleLinkContainer
          article={{
            title: articleTitle,
            id: value.articleId,
          }}
          handleRemove={() => {
            onChange({});
            setArticleTitle(null);
          }}
        />
      ) : (
        <RemoteSelect
          state={select}
          placeholder={placeholder}
          scale="lg"
          aria-label="Saisir le titre ou l’ID de l’article"
        />
      )}
    </div>
  );
});

const ArticleLinkContainer = memo(({ article, handleRemove }) => {
  return (
    <div className="align-center mt-2 flex h-9 justify-between">
      <ArticleLink articleId={article.id} target="_blank">
        {article.title}
      </ArticleLink>
      <Button
        title="Délier l’article"
        appearance="text"
        variant="secondary"
        scale="sm"
        iconOnly
        onClick={() => handleRemove()}
      >
        <IoCloseCircleOutline />
      </Button>
    </div>
  );
});
