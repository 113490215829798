import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { Button } from "swash/Button";
import { Chip } from "swash/Chip";
import { Dialog, DialogStore } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useToaster } from "swash/Toast";

import { AuthorAvatar } from "@/components/AuthorAvatar";
import { useAmplitude } from "@/containers/Amplitude";
import { ArticleAuthor } from "@/containers/article/ArticleAuthorsSelect";
import { ArticleAuthorsSelect_articleFragment } from "@/gql-types";

const AuthorTag = ({ author }: { author: ArticleAuthor }) => {
  const label = author?.shortName
    ? `${author.longName} (${author.shortName})`
    : author.longName;

  return (
    <Chip key={author.id}>
      <div className="flex items-center">
        <AuthorAvatar author={author} />
        <span className="pl-1">{label}</span>
      </div>
    </Chip>
  );
};

const Mutation = gql`
  mutation AuthorsNotificationDialog_sendNotifications(
    $input: SendNotificationsInput!
  ) {
    sendNotifications(input: $input)
  }
`;

export const logNotificationsEvent = ({
  logEvent,
  eventType,
  authors,
  article,
}: {
  logEvent?: (eventType: string, eventProperties?: Record<string, any>) => void;
  eventType: string;
  authors: ArticleAuthor[];
  article: ArticleAuthorsSelect_articleFragment;
}) => {
  if (logEvent) {
    authors.map((author) =>
      logEvent(eventType, {
        id: author.id,
        longName: author.longName,
        linked: Boolean(author.user),
        verified: author.verified,
        ...(author.user && {
          currentRole: author.user.currentRole?.name,
          currentSection: author.user.currentSection?.name,
        }),
        articleId: article.id,
        articleTitle: article.title,
        articleSection: article.sections[0]?.name,
      }),
    );
  }
};

export const AuthorsNotificationDialog = ({
  article,
  authors,
  store,
}: {
  article: ArticleAuthorsSelect_articleFragment;
  authors: ArticleAuthor[];
  store: DialogStore;
}) => {
  const toaster = useToaster();
  const { logEvent } = useAmplitude();
  const [sendNotifications] = useMutation(Mutation, {
    onError: () => {
      toaster.danger(
        authors?.length > 1
          ? "L’envoi de notification aux signataires a échoué"
          : "L’envoi de notification au signataire a échoué",
      );
    },
    onCompleted: () => {
      store.hide();
    },
  });

  const handleSendNotifications = useCallback(() => {
    sendNotifications({
      variables: {
        input: {
          type: "subjects.created",
          recipientIds: authors.map(({ user }) => user?.id).filter(Boolean),
          resource: { articleId: article.id },
        },
      },
    });

    logNotificationsEvent({
      logEvent,
      eventType: "Signatory Notified",
      authors,
      article,
    });
  }, [sendNotifications, authors, article, logEvent]);

  const handleClose = useCallback(() => {
    store.hide();
    logNotificationsEvent({
      logEvent,
      eventType: "Signatory Unnotified",
      authors,
      article,
    });
  }, [store, authors, article, logEvent]);

  return (
    <Dialog store={store} hideOnInteractOutside={false} hideOnEscape={false}>
      <PanelHeader
        title="Souhaitez-vous notifier les nouveaux signataires ajoutés ?"
        onClose={handleClose}
      />
      <PanelBody>
        <div className="pb-2">
          Une notification sera envoyée aux signataires que vous venez d’ajouter
          dont la signature est liée à un compte utilisateur Sirius, listés
          ci-dessous.
        </div>
        <div className="flex flex-wrap gap-1">
          {authors.map((author) => (
            <AuthorTag key={author.id} author={author} />
          ))}
        </div>
      </PanelBody>
      <PanelFooter>
        <Button
          type="button"
          variant="secondary"
          appearance="text"
          onClick={handleClose}
        >
          Ne pas envoyer
        </Button>
        <Button type="button" onClick={handleSendNotifications}>
          Envoyer
        </Button>
      </PanelFooter>
    </Dialog>
  );
};
