import { TypedDocumentNode, gql } from "@apollo/client";
import { FormApi } from "final-form";
import moment from "moment";
import { useCallback, useMemo } from "react";

import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { PUBLICATIONS } from "@/config/constants";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";
import { getWorkspaceType } from "@/containers/routes/home-edition/util";
import type {
  ListPublicationsQuery,
  ListPublicationsQueryVariables,
} from "@/gql-types";

import { useSafeQuery } from "../Apollo";

export const defaultSelectLabel = "Sélectionner une édition";

export function formatEditionLabel(date: string) {
  return `Edition du ${moment(date).format("DD/MM/YYYY")}`;
}

const ListPublicationsQuery: TypedDocumentNode<
  ListPublicationsQuery,
  ListPublicationsQueryVariables
> = gql`
  query ListPublications($name: String!, $type: String!) {
    publications(
      limit: 60
      where: { name: { eq: $name }, type: { eq: $type } }
    ) {
      nodes {
        id
        date
      }
    }
  }
`;

type FormValues = { publicationDate: string };

type ListPublicationsSelectorProps = {
  workspace: string;
  userPublications: { id: number }[];
  isLaMatinalePublication?: boolean;
  onClickEdition: (edition: {
    id: number;
    type: string;
    title: string;
    name: string;
  }) => void;
};
export const ListPublicationsSelector: React.FC<
  ListPublicationsSelectorProps
> = ({ userPublications, workspace, onClickEdition }) => {
  const { data } = useSafeQuery(ListPublicationsQuery, {
    variables: {
      name: workspace,
      type: getWorkspaceType(workspace),
    },
    fetchPolicy: "network-only",
  });

  const listPublications = useMemo(() => {
    if (!data) return [];
    const userPublicationIds = userPublications?.map((pub) => pub.id);
    return data.publications.nodes.filter(
      ({ id }) => !userPublicationIds.includes(id),
    );
  }, [userPublications, data]);

  const handleSubmit = useCallback(
    ({ publicationDate }: FormValues, form: FormApi<FormValues>) => {
      const publication = listPublications.find(
        ({ date }) => date === publicationDate,
      );
      if (!publication) return;
      onClickEdition({
        type: PUBLICATIONS.GENERIC_NAME,
        id: publication.id,
        title: formatEditionLabel(publicationDate),
        name: `publication-${publication.id}`,
      });
      form.reset();
    },
    [onClickEdition, listPublications],
  );

  const enumPublications = useNodesToEnumArray(listPublications, {
    valueSelector: "date",
    labelSelector: "date",
  });

  return (
    <Form onSubmit={handleSubmit}>
      <FormAutoSubmit />
      <EnumField
        aria-label="Sélectionnez une édition"
        placeholder="Sélectionnez une édition"
        enum={enumPublications}
        name="publicationDate"
        sortEntries={() => false}
        labelSelector={({ label }: { label: string }) =>
          formatEditionLabel(label)
        }
      />
    </Form>
  );
};
