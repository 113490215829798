import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, React.ComponentProps<"svg">>(
  function SvgIndice3(props, ref) {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
          <path d="m8 6 4 4-4 4M1.5 5.5l4 4.5-4 4.5M14 6l4 4-4 4" />
        </g>
      </svg>
    );
  },
);
