import React, {
  Children,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "swash/Button";
import { Dialog, DialogProps, DialogStore } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { Textbox } from "swash/controls/Textbox";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useStoreState } from "swash/utils/useStoreState";

import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";

interface ConfirmDialogContext {
  submitButtonProps: { disabled: boolean };
}

const ConfirmDialogContext = createContext<ConfirmDialogContext>({
  submitButtonProps: { disabled: false },
});

interface ConfirmDialogProps extends DialogProps {
  store: DialogStore;
  onConfirm: () => Promise<void | boolean>;
  children: React.ReactNode;
  title: string;
  /**@default "Confirmer"*/
  confirmButtonLabel?: string;
  /**@default "Annuler"*/
  cancelButtonLabel?: string;
  confirmationText?: string;
  /**@default false*/
  disabled?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  store,
  onConfirm,
  children,
  title,
  confirmationText,
  confirmButtonLabel = "Confirmer",
  cancelButtonLabel = "Annuler",
  disabled = false,
  ...props
}) => {
  const [userInput, setUserInput] = useState("");

  const handleChange = useEventCallback((e) =>
    setUserInput(e.target?.value ?? e),
  );

  const confirmDialogBody = Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === ConfirmDialogBody,
  );

  const confirmDialogFooterActions = Children.toArray(children).filter(
    (child) =>
      React.isValidElement(child) && child.type === ConfirmDialogFooterActions,
  );

  const submitDisabled =
    disabled || (Boolean(confirmationText) && userInput !== confirmationText);

  const value = useMemo(
    () => ({
      submitButtonProps: {
        disabled: submitDisabled,
      },
    }),
    [submitDisabled],
  );

  const open = useStoreState(store, "open");

  useEffect(() => {
    if (!open) {
      //reset input
      setUserInput("");
    }
  }, [open]);

  return (
    <ConfirmDialogContext.Provider value={value}>
      <Dialog store={store} {...props}>
        <Form
          onSubmit={async () => {
            const defaultPrevented = await onConfirm();
            if (defaultPrevented) return;
            store.hide();
          }}
        >
          <FormErrorToaster />
          <PanelHeader title={title} onClose={store.hide} />
          <PanelBody>
            <ConfirmDialogBody>{confirmDialogBody}</ConfirmDialogBody>
            {confirmationText && (
              <div className="flex flex-col gap-4 py-4">
                <div>
                  Pour confirmer, écrivez{" "}
                  <strong>« {confirmationText} »</strong>
                  {confirmationText === confirmationText.toUpperCase() &&
                    " en majuscules"}{" "}
                  dans le champ ci-dessous, puis appuyez sur le bouton «{" "}
                  {confirmButtonLabel} »
                </div>
                <Textbox
                  onChange={handleChange}
                  aria-label="Confirmation"
                  value={userInput}
                />
              </div>
            )}
          </PanelBody>
          <PanelFooter>
            <ConfirmDialogFooterActions>
              <Button
                type="button"
                variant="secondary"
                appearance="text"
                onClick={store.hide}
              >
                {cancelButtonLabel}
              </Button>
              {!confirmDialogFooterActions.length && (
                <FormSubmit variant="danger" disabled={submitDisabled}>
                  {confirmButtonLabel}
                </FormSubmit>
              )}
              {confirmDialogFooterActions}
            </ConfirmDialogFooterActions>
          </PanelFooter>
        </Form>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
};

interface ConfirmDialogBodyProps {
  children: ReactNode;
}

export const ConfirmDialogBody: React.FC<ConfirmDialogBodyProps> = ({
  children,
}) => {
  return <>{children}</>;
};

interface ConfirmDialogFooterActionProps {
  children:
    | ReactNode
    | ((props: Omit<ConfirmDialogFooterActionProps, "children">) => ReactNode);
}

export const ConfirmDialogFooterActions: React.FC<
  ConfirmDialogFooterActionProps
> = ({ children }) => {
  const props = useContext(ConfirmDialogContext);
  return <>{typeof children === "function" ? children(props) : children}</>;
};
